import { Component, OnInit } from '@angular/core';
import * as AOS from 'aos';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ZohoService } from '../../zoho.service';
import { EmailService } from 'src/app/email.service';
import { CustomerIOService } from 'src/app/customer-io.service';
import { imgContent_security } from 'src/constants/product-content';
import { environment } from 'src/environments/environment';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-tally-dms-add-on',
  templateUrl: './tally-dms-add-on.component.html',
  styleUrls: ['./tally-dms-add-on.component.scss']
})
export class TallyDMSAddOnComponent implements OnInit {
  
   
     /*
        interface path for images 
     */
   
    //  imagePathTally: string='../../../assets/Image/tally-dms.svg';
   
    //  imagePathList: string='../../../assets/Image/easy-one-click.svg';
   
    //  imagePathList1: string='../../../assets/Image/stop-printing.svg';
   
    //  imagePathList2: string='../../../assets/Image/anytime.svg';
   
    //  imagePathList3: string='../../../assets/Image/security1.svg';
   
    //  imagePathList4: string='../../../assets/Image/go-digital.svg'
   
    //  imagePathList5: string='../../../assets/Image/reduce-cost1.svg'
   
    //  dmsForm: FormGroup;
    //  isLoading = false;
    //  firstNameValidDiv: boolean = false;
    //  lastNameValidDiv: boolean = false;
    //  emailValidDivEmpty: boolean = false;
    //  emailValidDivInvalid: boolean = false;
    //  phoneValidDiv: boolean = false;
    //  submittedForm: boolean = false;
   
    //  constructor(private fb: FormBuilder, private zohoService: ZohoService, private titleService: Title, private emailService: EmailService, private customerIOService: CustomerIOService) {
    //    /*
    //     form validations
    //    */
    //    this.dmsForm = this.fb.group({
    //      firstname: ['', [Validators.required, Validators.minLength(2)]],
    //      lastname: ['', [Validators.required, Validators.minLength(2)]],
    //      email: ['', [Validators.required, Validators.email,
    //       Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/),]],
    //      phone: ['', [Validators.required, Validators.pattern(/^\d+$/), Validators.maxLength(15)]],
    //    });
    //  }
   
    //  /*
    //  resetting error message of last name input on keypress
    //  */
    //  resetLastNameDiv(event: Event) {
    //    this.lastNameValidDiv = false;
    //  }
   
    //  /*
    //  resetting error message of first name input on keypress
    //  */
    //  resetFirstNameDiv(event: Event) {
    //    this.firstNameValidDiv = false;
    //  }
   
    //  /*
    //  resetting error message of email input on keypress
    //  */
    //  resetEmailDiv(event: Event) {
    //    this.emailValidDivEmpty = false;
    //    this.emailValidDivInvalid = false;
    //  }
   
    //  /*
    //  resetting error message of phone input on keypress
    //  */
    //  resetPhoneDiv(event: Event) {
    //    this.phoneValidDiv = false;
    //  }
   
    //  /*
    //  This method will send the payload for zoho, customer.io and email service on successful validations of all input fields
    //  */
    //  onSubmit() {
    //  if(this.dmsForm.get('firstname')?.hasError('required')) {
    //    this.firstNameValidDiv = true;
    //  }
   
    //  if(this.dmsForm.get('lastname')?.hasError('required')) {
    //    this.lastNameValidDiv = true;
    //  }
   
    //  if(this.dmsForm.get('email')?.hasError('required')) {
    //    this.emailValidDivEmpty = true;
    //  }
   
    //  if(this.dmsForm.get('email')?.hasError('email')) {
    //    this.emailValidDivInvalid = true;
    //  }
   
    //  if(this.dmsForm.get('phone')?.hasError('required')) {
    //    this.phoneValidDiv = true;
    //  }

    //   let countryName = localStorage.getItem("CountryName");
    //   let dialCode = localStorage.getItem("DialCode");
    //   let contactNumber = '';
   
    //    if (this.dmsForm.valid) {
    //        this.submittedForm = true;
    //        countryName = countryName || "India";
    //        dialCode = dialCode || "91";
    //        contactNumber = "+" + dialCode + this.dmsForm.value.phone;
   
    //        /*
    //        payload for zoho service
    //      */
    //        const firstName = this.dmsForm.value.firstname;
    //        const lastName = this.dmsForm.value.lastname;
    //        const fullName = `${firstName} ${lastName}`;
    //        const email = this.dmsForm.value.email;
    //        const WS_SubscriptionStatus = "Unpaid Customer";
    //        const leadSource = 'WS-Downloadtally';
    //        const address1 = '';
    //        const address2 = '';
    //        const phoneNo = contactNumber;
    //        const imgContent_security = '';
    //        const state = '';
    //        const country = countryName;
    //        const orgName = '';
    //        const companySize = '';
    //        const jobTitle = '';
    //        const salutation = '';
    //        const message1 = '';
    //        const message2 = '';
   
    //        /*
    //          function to send data to zoho service
    //        */
   
    //        this.zohoService.sendUserData(firstName, lastName, fullName, 
    //          email, 
    //          WS_SubscriptionStatus, 
    //          leadSource, 
    //          address1,
    //          address2,
    //          phoneNo,
    //          imgContent_security,
    //          state,
    //          country,
    //          orgName,
    //          companySize,
    //          jobTitle,
    //          salutation,
    //          message1,
    //          message2)
    //        .subscribe(
    //          (response) => {
    //            console.log('zoho lead sent:', response);
    //          },
    //          error => {
    //            console.error('Email Error:', error);
    //          }
    //        );
   
    //        /*
    //        payload for email service
    //        */
    //        const recipientEmail = this.dmsForm.value.email;
    //        const messageId = environment.DownloadTallyMessageID;
    //        const messageData = { First_Name: this.dmsForm.value.firstname };
   
    //          /*
    //          function to send data to email service
    //        */
    //        this.emailService.sendEmail(recipientEmail, messageId, messageData, email)
    //          .subscribe(
    //            (response) => {
    //              console.log('Email sent:', response);
    //            },
    //            (error) => {
    //              console.error('Email Error:', error);
    //            }
    //          );
   
               
    //        /*
    //          function to send data to customer.io
    //        */
    //            this.customerIOService.updateUserLeadSource(email, leadSource)
    //              .subscribe(
    //                (response) => {
    //                  console.log('User lead source updated successfully:', response);
    //                },
    //                (error) => {
    //                  console.error('Error updating user lead source:', error);
    //                }
    //              );
    //      }
   
         
    //    }
     
   
   
   
   
     ngOnInit() {
    //    AOS.init();
    //    this.titleService.setTitle(' Simplify Document Management in Tally with Cloud DMS Plugin: Improve Productivity'); /** For Page Title */
    //    let accordianBodyFirst = document.getElementById("collapseOne");
    //    accordianBodyFirst?.classList.add('show');
      }

    //  scrollToDownloadTallyForm() {
    //   let windowURL = window.location.href.split('/')[3];
    //   const downloadFormSection = document.getElementById('downloadForm');
    //   if (downloadFormSection) {
    //     const viewportHeight = window.innerHeight;
    //       const elementHeight = downloadFormSection.clientHeight;
    //       const yOffset = (viewportHeight - elementHeight) / 2;
    //       const scrollOptions: ScrollToOptions = {
    //       top: downloadFormSection.offsetTop - yOffset,
    //       behavior: 'smooth',
    //     };
    //       window.scrollTo(scrollOptions);
    //     //downloadFormSection.scrollIntoView({ behavior: 'smooth' });
    //   }
    //   return;
    // }

}
