<footer class="footer-display">
  <div class="container-fluid overflow-hidden">
      <div class="row column-reverse">
          <div class="col-lg-2 col-xxl-2 col-xl-2 col-md-12 col-sm-12 col-12 p-0">
              <!-- logo and social icons section -->
              <div  class="find-ws-url d-xl-flex d-xxl-flex d-lg-flex d-md-flex d-sm-flex d-flex flex-column flex-sm-column flex-md-column flex-lg-column   flex-xl-column flex-xxl-column justify-content-between">
                  <div id="nav">
                      <a href="https://dox2u.com/"><img src="../../../assets/Image/footer-logo.svg"
                              alt="workspace"
                              class="img-fluid logo d-sm-none d-md-none d-lg-block d-xl-block mobile-none"></a>
                      <ul class="p-0 display1024">
                          <li><a (click)="navigateToPrivacyPolicy('scroll-to-Policy')">Privacy Policy</a></li>
                          <li><a (click)="navigateToPrivacyPolicy('scroll-to-term-usage')">Terms & Conditions </a>
                          </li>
                          <li><a (click)="navigateToPrivacyPolicy('section-head-disclaimer')">Disclaimer & Trademark Attribution
                                  </a></li>
                      </ul>
                  </div>

                  <div class="centeralign">
                      <div class="social social1199 socialDisplay">
                          <a href="https://twitter.com/Dox2U" target="_blank"><img
                                  src="../../../assets/Image/footer-twitter.svg" alt=""
                                  class="img-fluid twitter"></a>
                          <a href="https://www.linkedin.com/company/dox-2u/" target="_blank"><img
                                  src="../../../assets/Image/footer-linkedin.svg" alt=""
                                  class="img-fluid linkedin"></a>
                          <a href="https://www.instagram.com/dox2U/" target="_blank"> <img
                                  src="../../../assets/Image/footer-instagram.svg" alt=""
                                  class="img-fluid insta"></a>
                      </div>
                  </div>
              </div>
          </div>
          <!-- logo and social icons section-end -->
          <!--footer-navigation -->
          <div class="col-lg-10 col-xl-10 col-xxl-10 col-md-12 col-sm-12 col-12 width1200">
              <div class="row">
                  <div class="footer-nav d-flex flex-xxl-row flex-xl-row flex-lg-row flex-sm-column flex-column"
                      id="nav">
                      <div class="col-lg-2 width1200child">
                          <h5 class="color1024" (click)="expandProduct()">Product <span 
                                  class="d-block d-sm-block d-md-block d-lg-none d-xxl-none d-xl-none"><img
                                      src="../../../assets/Image/white-dropdown-icon.svg" alt=""
                                      class="img-fluid"></span></h5>
                          <ul class="p-0 display1024" *ngIf="productItems">
                              <li><a href="feature">Features</a></li>
                              <!-- <li><a href="product/features">Workspaces</a></li> -->
                             
                              <!-- <li><a href="Tally-DMS-Add-on.php">Tally DMS Add-on</a></li> -->
                              <li><a href="document-handling">Document Handling</a></li>
                                      <li><a href="smart-cabinet">Smart Cabinet</a></li>
                                      <li><a href="deep-search">Deep search</a></li>
                                      <li><a href="text-extraction">Text Extraction</a></li>
                                      <li><a href="guest-access">Guest Access</a></li>
                                      <li><a href="security">Security &
                                      Reliability</a></li>
                             
                         
                       
                             
                           
                             
                              
                              <li><a href="dockets">Dockets</a></li>

                          </ul>
                      </div>
                      <div class="col-lg-2 width1200child">
                          <h5 class="color1024" (click)="expandIntegrations()">Integrations <span
                                  class="d-block d-sm-block d-md-block d-lg-none d-xxl-none d-xl-none"><img
                                      src="../../../assets/Image/white-dropdown-icon.svg" alt=""
                                      class="img-fluid"></span></h5>
                          <ul class="p-0 display1024" id="nav" *ngIf="integrationsItems">
                              <li><a href="tally">Tally DMS Plugin</a></li>
                              <li><a href="tally">Integrations</a></li>

                              <!-- <li><a>Free vs Paid </a></li> -->

                          </ul>
                      </div>

                      <div class="col-lg-2 width1200child">
                          <h5 class="color1024" (click)="expandPricing()">Pricing <span
                                  class="d-block d-sm-block d-md-block d-lg-none d-xxl-none d-xl-none"><img
                                      src="../../../assets/Image/white-dropdown-icon.svg" alt=""
                                      class="img-fluid"></span></h5>
                          <ul class="p-0 display1024" id="nav" *ngIf="pricingItems">
                              <li ><a class="dsub" href="pricing"  >Subscription </a></li>
                              <!-- <li><a href="pricing/paid-vs-free">Free vs Paid </a></li> -->

                              <!-- <li><a>Free vs Paid </a></li> -->




                              
                          </ul>
                      </div>
                      <div class="col-lg-2 width1200child">
                          <h5 class="color1024" (click)="expandPartners()">Partners <span
                                  class="d-block d-sm-block d-md-block d-lg-none d-xxl-none d-xl-none"><img
                                      src="../../../assets/Image/white-dropdown-icon.svg" alt=""
                                      class="img-fluid"></span></h5>
                          <ul class="p-0 display1024" id="nav" *ngIf="partnersItems">
                              <li ><a class="dsub" href="partners">Partner with Us </a></li>
                              <!-- <li><a href="pricing/paid-vs-free">Free vs Paid </a></li> -->

                              <!-- <li><a>Free vs Paid </a></li> -->
                          </ul>
                      </div>

                      <div class="col-lg-2 width1200child ">
                          <h5 class="color1024" (click)="expandResources()">Resources <span
                                  class="d-block d-sm-block d-md-block d-lg-none d-xxl-none d-xl-none d-sm-block"><img
                                      src="../../../assets/Image/white-dropdown-icon.svg" alt=""
                                      class="img-fluid"></span></h5>
                          <ul class="p-0 display1024" *ngIf="resourcesItems">
                              <li><a href="https://blog.dox2u.com/" target="_blank">Blog</a></li>
                              <li><a href="https://help.dox2u.com/" target="_blank">Help Center</a></li>
                              <!-- <h5 class="color1024 margin01200px margin0991 mbottom4 ">Why dox2U?</h5> -->
                              <li class="whyDoxfont" style="color: #b0c4ff; font-size: 18px; font-weight: 800;">Why dox2U?</li>
                                <li><a href="problems-we-are-solving">Problems
                                      we're solving</a></li>
                              <li><a href="why-choose-dox2u">Why choose
                                      dox2U?</a></li>
                              <li><a href="built-for-everyone">Built for
                                      everyone</a></li>
                              <!-- <li><a href="why-dox2U/paid-vs-free">Paid vs Free</a></li> -->
                              <!-- <li><a href="dummy-page.php">Scale</a></li> -->

                        </ul>
                        
                            
                      </div>
                      <!-- legal -->
                      <div
                          class="col-lg-2 d-block d-sm-block d-md-block d-lg-none d-xxl-none d-xl-none width1200child">
                          <h5 class="legalMargin" (click)="expandLegal()">Legal <span
                                  class="d-block d-sm-block d-md-block d-lg-none d-xxl-none d-xl-none"><img
                                      src="../../../assets/Image/white-dropdown-icon.svg" alt=""
                                      class="img-fluid"></span></h5>
                          <ul class="p-0 display1024" *ngIf="legalItems">
                              <li><a (click)="navigateToPrivacyPolicy('scroll-to-Policy')">Privacy Policy</a></li>
                              <li><a (click)="navigateToPrivacyPolicy('scroll-to-term-usage')">Terms & Conditions
                                  </a></li>
                              <li><a (click)="navigateToPrivacyPolicy('section-head-disclaimer')">Disclaimer
                                     </a></li>
                          </ul>
                      </div>
                      <!-- legal -->
                      <div 
                          class="col-lg-2 d-block d-sm-block d-md-block d-lg-block d-xxl-block d-xl-block width1200child">
                          <h5 class="color1024" (click)="expandCompany()">Company <span
                                  class="d-block d-sm-block d-md-block d-lg-none d-xxl-none d-xl-none"><img
                                      src="../../../assets/Image/white-dropdown-icon.svg" alt=""
                                      class="img-fluid"></span></h5>
                          <ul class="p-0 display1024" *ngIf="companyItems">
                              <li><a href="contact-us">Contact Us</a></li>
                              <li><a href="https://privetonline.com/join-our-team.php" target="_blank">Join team
                                      dox2U</a></li>
                              <li><a href="https://calendly.com/dox2u-dms/dox2u-demo" target="_blank">Schedule a
                                      demo</a></li>

                          </ul>

                      </div>

                  </div>
              </div>
          </div>
      </div>
  </div>

  <div class="centeralign displayresolution">
    <div class="social social1199 socialDisplay text-center">
        <a href="https://twitter.com/Dox2U" target="_blank"><img
                src="../../../assets/Image/footer-twitter.svg" alt=""
                class="img-fluid twitter"></a>
        <a href="https://www.linkedin.com/company/dox-2u/" target="_blank"><img
                src="../../../assets/Image/footer-linkedin.svg" alt=""
                class="img-fluid linkedin"></a>
        <a href="https://www.instagram.com/dox2U/" target="_blank"> <img
                src="../../../assets/Image/footer-instagram.svg" alt=""
                class="img-fluid insta"></a>
    </div>
</div>

 

</footer>
<div class="footer-bottom">
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-xxl-12 w-100">
                <div class="row">
                    <div class="col-xxl-10 col-xl-10 col-lg-12 col-md-12 col-sm-12 mx-auto">
                        <p>© 2021 - 2024 All rights reserved.</p>
                        <p class="privet_link">Engineered and marketed by Privet Ventures Pvt Ltd</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
