import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomerIOService } from 'src/app/customer-io.service';
import { EmailService } from 'src/app/email.service';
import { ZohoService } from 'src/app/zoho.service';
import { environment } from 'src/environments/environment';
import { Meta, Title } from '@angular/platform-browser';
import { PhoneNumberUtil } from "google-libphonenumber";
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.scss'],
})
export class ContactusComponent implements OnInit{
  contactForm: FormGroup;
  isLoading = false;
  firstNameValidDiv: boolean = false;
  lastNameValidDiv: boolean = false;
  emailValidDivEmpty: boolean = false;
  emailValidDivInvalid: boolean = false;
  phoneValidDiv: boolean = false;
  phoneValidationCheckDiv: boolean = false;
  JobtitleValidDivEmpty: boolean = false;
  CompanyNameValidDivEmpty: boolean = false;
  CountryValidDivEmpty: boolean = false;
  CompanySizeValidDivEmpty: boolean = false;
  CountryDivEmpty: boolean = false;
  helpValidDivEmpty: boolean = false;
  contactFormSubmitted: boolean = false;
  contactusFormSubmitted: boolean = true;
  SearchCountryField = SearchCountryField;
	CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  separateDialCode!: false;
  phoneNumberUtil: PhoneNumberUtil = PhoneNumberUtil.getInstance();
  countryName: string = 'India';
  dialCode: Number = 91;
  contactNumber: any;

  constructor(private meta: Meta, private fb: FormBuilder, private titleService: Title, private emailService: EmailService, private zohoService: ZohoService,private customerIOService: CustomerIOService,) {
     /*
     * Cannonical tag
     */
    
  /*
  form validations
  */
    this.contactForm = this.fb.group({
      firstname: ['', [Validators.required, Validators.minLength(2)]],
      lastname: ['', [Validators.required, Validators.minLength(2)]],
      email: ['', [Validators.required, Validators.email,
        Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/),]],
      phone: [ '',[Validators.required, Validators.maxLength(15),], ],
      Jobtitle: ['', [Validators.required]],
      CompanyName: ['', [Validators.required]],
      CompantSize: ['', [Validators.required]],
      Country: ['', [Validators.required]],
      HowCanHelp: ['', [Validators.required]],
    });
  }
  ngOnInit(): void {
    this.meta.addTag({rel: 'canonical', content: 'https://www.dox2u.com/contact-us'});
    this.titleService.setTitle('Contact dox2U - Document Management System Software in India'); /** For Page Title */
  }

  /*
  resetting error message of last name input on keypress
  */
  resetLastNameDiv(event: Event) {
    this.lastNameValidDiv = false;
  }

   /**
   *When Country is changed from dropdown 
   */
  //  CountryChange(event: Event){
    
  //   this.countryDialCode=dialCode
  //   this.countryNameInitial=countryCode
  //   this.countryFlagClass=flagClass
  //   console.log(this.countryDialCode, this.countryNameInitial, this.countryFlagClass)
  // }

  // checkPhonenoValidation() {
  //   let phoneNumber = this.contactForm.get('phone')?.value;
  //   let countryCode = (localStorage.getItem('countryCode'))?.toUpperCase(); 
  //   let isPhonenoValid = this.validatePhoneNumber(phoneNumber, countryCode);
  //  if(!isPhonenoValid) {
  //   this.phoneValidationCheckDiv = true;
  //  }
  // }

  validatePhoneNumber(phoneNumber: string, countryCode: any) {
    const parsedNumber = this.phoneNumberUtil.parseAndKeepRawInput(phoneNumber, countryCode);
      const isValidNumber = this.phoneNumberUtil.isValidNumber(parsedNumber);
      const numberType = this.phoneNumberUtil.getNumberType(parsedNumber);
      console.log(parsedNumber, isValidNumber, numberType)
      return isValidNumber;

      
  }

  /*
  resetting error message of first name input on keypress
  */
  resetFirstNameDiv(event: Event) {
    this.firstNameValidDiv = false;
  }

  /*
  resetting error message of email input on keypress
  */
  resetEmailDiv(event: Event) {
    this.emailValidDivEmpty = false;
    this.emailValidDivInvalid = false;
  }

  /*
  resetting error message of phone input on keypress
  */
  resetPhoneDiv(event: Event) {
    this.phoneValidDiv = false;
    this.phoneValidationCheckDiv = false;
  }

  /*
  resetting error message of job-title input on keypress
  */
  resetjobTitleDiv(event: Event) {
    this.JobtitleValidDivEmpty = false;
  }

  /*
  resetting error message of company-name input on keypress
  */
  resetCompanyNameDiv(event: Event) {
    this.CompanyNameValidDivEmpty = false;
  }

  /*
  resetting error message of country input on keypress
  */
  resetCountryDiv(event: Event) {
    this.CountryDivEmpty = false;
  }

  /*
  resetting error message of company-size input on keypress
  */
  resetCompanySizeDiv(event: Event) {
    this.CompanySizeValidDivEmpty = false;
  }

 /*
  resetting error message of company-help input on keypress
  */
  resetCompanyHelpDiv(event: Event) {
    this.helpValidDivEmpty = false;
  }

  onCountryChangeCF(event: any) {
    this.countryName = event.name;
    this.dialCode = event.dialCode;
    this.contactNumber = event.e164Number;
    console.log('Selected Country ISO:', event.iso2);
    console.log('Selected Country Name:', this.countryName);
    console.log('Selected Country Dial Code:', this.dialCode);
  }


  /*
  This method will send the payload for zoho, customer.io and email service on successful validations of all input fields
  */
  onSubmit() {
    if (this.contactForm.get('firstname')?.hasError('required')) {
      this.firstNameValidDiv = true;
    }

    if (this.contactForm.get('lastname')?.hasError('required')) {
      this.lastNameValidDiv = true;
    }

    if (this.contactForm.get('email')?.hasError('required')) {
      this.emailValidDivEmpty = true;
    }

    if(this.contactForm.controls['phone'].invalid) {
      this.phoneValidationCheckDiv = true;
      event?.preventDefault();
     }

    if (this.contactForm.get('phone')?.hasError('required')) {
      this.phoneValidDiv = true;
      this.phoneValidationCheckDiv = false;
    }

    if (this.contactForm.get('email')?.hasError('pattern')) {
      this.emailValidDivInvalid = true;
    }

    if (this.contactForm.get('Jobtitle')?.hasError('required')) {
      this.JobtitleValidDivEmpty = true;
    }
    if (this.contactForm.get('CompanyName')?.hasError('required')) {
      this.CompanyNameValidDivEmpty = true;
    }
    if (this.contactForm.get('CompantSize')?.hasError('required')) {
      this.CompanySizeValidDivEmpty = true;
    }
    if (this.contactForm.get('Country')?.hasError('required')) {
      this.CountryDivEmpty = true;
    }
    if (this.contactForm.get('HowCanHelp')?.hasError('required')) {
      this.helpValidDivEmpty = true;
    }

    //this.checkPhonenoValidation()
    // let phoneNumber = this.contactForm.get('phone')?.value;
    // let countryCode = (localStorage.getItem('countryCode'))?.toUpperCase(); 
    // let isPhonenoValid = this.validatePhoneNumber(phoneNumber, countryCode);


    // let dialCode = localStorage.getItem("DialCode");
    // let contactNumber = '';

    if (this.contactForm.valid && !this.phoneValidationCheckDiv) {
      this.isLoading = true;
      this.contactFormSubmitted = true;
      this.contactusFormSubmitted = false;
      const phoneValue = this.contactForm.value.phone;
      this.contactNumber = phoneValue.internationalNumber;
      console.log("contactNumber", this.contactNumber)
      console.log("dialCode", this.dialCode)
      console.log("phoneValue", this.contactNumber)
      console.log("countryName", this.countryName)
      // dialCode = dialCode || "91";
      // contactNumber = "+" + dialCode + this.contactForm.value.phone;
      /*
        payload for zoho service
      */
      const firstName = this.contactForm.value.firstname;
        const lastName = this.contactForm.value.lastname;
        const fullName = `${firstName} ${lastName}`;
        const email = this.contactForm.value.email;
        const orgName = this.contactForm.value.CompanyName;
        const phoneNo = this.contactNumber;
        const city = '';
        const country = this.contactForm.value.Country;
        const WS_SubscriptionStatus = "Unpaid Customer";
        const leadSource = "WS-ContactUs";
        const address1 = '';
        const address2 = '';        
        const state = '';
        const companySize = this.contactForm.value.CompantSize;
        const jobTitle = this.contactForm.value.Jobtitle;
        const salutation = '';
        const message1 = this.contactForm.value.HowCanHelp;
        const message2 = '';

        /*
          function to send data to zoho service
        */
        this.zohoService.sendUserData(firstName, lastName, fullName, 
          email, 
          WS_SubscriptionStatus, 
          leadSource, 
          address1,
          address2,
          phoneNo,
          city,
          state,
          country,
          orgName,
          companySize,
          jobTitle,
          salutation,
          message1,
          message2)
        .subscribe(
          (response) => {
            console.log('zoho lead sent:', response);
          },
          error => {
            console.error('Email Error:', error);
          }
        );

        /*
        payload for email service
        */
        const recipientEmail = this.contactForm.value.email;
        const messageId = environment.ContactUsFormMessageID;
        const messageData = { First_Name: this.contactForm.value.firstName};

          /*
          function to send data to email service
        */
        this.emailService.sendEmail(recipientEmail, messageId, messageData, email)
        .subscribe(
          (response) => {
            console.log('Email sent:', response);
          },
          (error) => {
            console.error('Email Error:', error);
          }
        );

        /*
          function to send data to customer.io
        */
        this.customerIOService.updateUserLeadSource(email, leadSource)
              .subscribe(
                (response) => {
                  console.log('User lead source updated successfully:', response);
                },
                (error) => {
                  console.error('Error updating user lead source:', error);
                }
              );
          
        }
    }
  }

