import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ContentBased, Contentfilture, Contentshort, deepSearchHero } from 'src/constants/product-content';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-deep-search',
  templateUrl: './deep-search.component.html',
  styleUrls: ['./deep-search.component.scss']
})
export class DeepSearchComponent implements OnInit {
  ngOnInit(): void {
    this.meta.addTag({rel: 'canonical', content: 'https://www.dox2u.com/deep-search'});
    let scriptBreadcrumbList = this.renderer2.createElement('script');
    scriptBreadcrumbList.type = `application/ld+json`;
    scriptBreadcrumbList.text = `{
      "@context": "https://schema.org/", 
      "@type": "BreadcrumbList", 
      "itemListElement": [{
        "@type": "ListItem", 
        "position": 1, 
        "name": "Home page",
        "item": "https://dox2u.com/"  
      },{
        "@type": "ListItem", 
        "position": 2, 
        "name": "Deep Search page",
        "item": "https://www.dox2u.com/deep-search"  
      }]
      }`
    
    this.renderer2.appendChild(this._document.body, scriptBreadcrumbList);
    
    this.titleService.setTitle('Document Retrieval with Deep Search | dox2U","Search and retrieval of documents have now become easy, thanks to dox2U’s Document Retrieval System Software with Deep Search.'); /** For Page Title */
  }

  constructor(private meta: Meta, private titleService: Title, private renderer2: Renderer2, @Inject(DOCUMENT) private _document: Document){
      /*
      * Cannonical tag
     */
             
  }

  readonly deepSearchHeroText: typeof deepSearchHero = deepSearchHero;
  readonly contentBasedText: typeof ContentBased = ContentBased;
  readonly ContentfiltureText: typeof Contentfilture = Contentfilture;
  readonly Contentshorttext: typeof Contentshort = Contentshort;
  // readonly morefeaturetext: typeof Contentshort = ;

  deephero_img:string="../../../../assets/Image/deepSearch/dox2u_search_banner.svg"
  searchContent_img:string="../../../../assets/Image/deepSearch/dox2u_search_content.svg"
  searchfiter_img:string="../../../../assets/Image/deepSearch/dox2u_search_filter.svg"
  searchShort_img:string="../../../../assets/Image/deepSearch/dox2u_search_sort.svg"
  tickright_img:string="../../../../assets/Image/deepSearch/Tick-Right.svg"

  scrollToGettingStarted(event: Event) {
    const gettingStartedElement = document.getElementById('gettingStarted');
    if (gettingStartedElement) {
      const viewportHeight = window.innerHeight;
      const elementHeight = gettingStartedElement.clientHeight;
      const yOffset = (viewportHeight - elementHeight) / 2;
      const scrollOptions: ScrollToOptions = {
        top: gettingStartedElement.offsetTop - yOffset,
        behavior: 'smooth',
      };
      window.scrollTo(scrollOptions);
      //gettingStartedElement.scrollIntoView({ behavior: 'smooth' });
    }
  }

}
