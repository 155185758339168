<header class="header header-display">
  <div class="container-fluid">
    
  <nav class="navbar navbar-expand-lg navbar-light p-0">
    <a class="navbar-brand" href="#"
        ><img src="../../../assets/Image/logo.png" alt="" srcset="" /></a>
      <button (click)="showNavbar()"
        class="navbar-toggler collapsed bordernone"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"  
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <span class="overlay" id="overlay" (click)="hideNavbar()"></span>
      <div class="collapse navbar-collapse" id="navbarSupportedContent" *ngIf="displayNavbar">
        <!-- <ul class="navbar-nav">
          <!-- <li class="nav-item active">
              <a class="nav-link" href="#">Home <span class="sr-only">(current)</span></a>
            </li> -->
        <!-- </ul> --> 
        <ul class="navbar-nav mx-auto cMenu">
          <li *ngFor="let menu of menus; let i = index"  class="nav-item active"  [ngClass]="{'dropdown': menu.expand === 'dropdown'}">
            <a
              class="nav-link"
              id="nav-list"
              [href]="menu.path"
              (click)="navigateTo(menu.path, i)"
              role="button"
              [attr.data-toggle]="menu.expand === 'dropdown' ? 'dropdown' : null"
              aria-haspopup="true"
              aria-expanded="false" 
              [ngClass]="{ 'disabledMenu': menu.disabled }"
              >{{ menu.name }} <img [src]="menu.icon" class="arrow-img">
            </a>
            <div id="dropdown-submenu" *ngIf="showDropdown && menu.expand === 'dropdown' && menu.name === 'Product'" class="dropdown-menu position-absolute megatop" aria-labelledby="navbarDropdown">
              <div class="container">
                <div class="row my-4" (click)="hideDropdown()">
                  <div class="col-xxl-4 col-xl-4 col-lg-4  col-sm-12 mb-4 mb-lg-0 resmargin ">
                    <a  routerLink="/feature" class="text-dark" >
                      <div class="row dropbox">
                        <div class="col-xl-2 col-lg-2 col-md-0 col-sm-0 p-0">
                          <img
                            src="../../../assets/Image/submenu-icon/features-nav-icon.svg"
                            class="img-fluid shadow-1-strong rounded"
                            alt=""
                          />
                        </div>
                        <div class="col-10 p-0">
                          <h6 class="mb-2 proSubmenu">Features</h6>
                          <p class="submenu-para">
                            Explore our exciting capabilities
                          </p>
                        </div>
                      </div>
                    </a>
                    <a routerLink="/document-handling" class="text-dark">
                      <div class="row dropbox">
                        <div class="col-xl-2 col-lg-2 col-md-0 col-sm-0 p-0">
                          <img
                            src="../../../assets/Image/submenu-icon/document-handling-nav-icon.svg"
                            class="img-fluid shadow-1-strong rounded"
                            alt="Los Angeles Skyscrapers"
                          />
                        </div>
                        <div class="col-10 p-0">
                          <h6 class="mb-2 proSubmenu">Document Handling</h6>
                          <p class="submenu-para">Tag, Edit and Colaborate</p>
                        </div>
                      </div>
                    </a>
                    <a routerLink="/security" class="text-dark">
                      <div class="row dropbox">
                        <div class="col-xl-2 col-lg-2 col-md-0 col-sm-0 p-0">
                          <img
                            src="../../../assets/Image/submenu-icon/security-nav-icon.svg"
                            class="img-fluid shadow-1-strong rounded"
                            alt="Skyscrapers"
                          />
                        </div>
                        <div class="col-10 p-0">
                          <h6 class="mb-2 proSubmenu">Security & Reliability</h6>
                          <p class="submenu-para">Bank grade security</p>
                        </div>
                      </div>
                    </a>
                    <a routerLink="smart-cabinet" class="text-dark">
                      <div class="row dropbox">
                        <div class="col-xl-2 col-lg-2 col-md-0 col-sm-0 p-0">
                          <img
                            src="../../../assets/Image/submenu-icon/smart-cabinet-nav-icon.svg"
                            class="img-fluid shadow-1-strong rounded"
                            alt="Skyscrapers"
                          />
                        </div>
                        <div class="col-10 p-0">
                          <h6 class="mb-2 proSubmenu">Smart Cabinet</h6>
                          <p class="submenu-para">Intuitive virtual cabinet</p>
                        </div>
                      </div>
                    </a>
                    <a routerLink="text-extraction" class="text-dark">
                      <div class="row dropbox">
                        <div class="col-xl-2 col-lg-2 col-md-0 col-sm-0 p-0">
                          <img
                            src="../../../assets/Image/submenu-icon/text-extraction-nav-icon.svg"
                            class="img-fluid shadow-1-strong rounded"
                            alt="Hollywood Sign on The Hill"
                          />
                        </div>
                        <div class="col-10 p-0">
                          <h6 class="mb-2 proSubmenu">Text Extraction</h6>
                          <p class="submenu-para">
                            Extract from unstructured text and images
                          </p>
                        </div>
                      </div>
                    </a>
                  </div>
                 
                  <div class="col-xxl-4 col-xl-4 col-lg-4  col-sm-12 mb-4 mb-md-0 resmargin">
                    <!-- <p class="text-uppercase font-weight-bold">
                        Iste quaerato
                      </p> -->
                    
                    <a routerLink="guest-access" class="text-dark">
                      <div class="row dropbox">
                        <div class="col-xl-2 col-lg-2 col-md-0 col-sm-0 p-0">
                          <img
                            src="../../../assets/Image/submenu-icon/guest-access-nav-icon.svg"
                            class="img-fluid shadow-1-strong rounded"
                            alt="Palm Springs Road"
                          />
                        </div>
                        <div class="col-10 p-0">
                          <h6 class="mb-2 proSubmenu">Guest Access</h6>
                          <p class="submenu-para">Share docs securly</p>
                        </div>
                      </div>
                    </a>
                    <a routerLink="deep-search" class="text-dark">
                      <div class="row dropbox">
                        <div class="col-xl-2 col-lg-2 col-md-0 col-sm-0 p-0">
                          <img
                            src="../../../assets/Image/submenu-icon/deep-search-icon.svg"
                            class="img-fluid shadow-1-strong rounded"
                            alt="Los Angeles Skyscrapers"
                          />
                        </div>
                        <div class="col-10 p-0">
                          <h6 class="mb-2 proSubmenu">Deep Search</h6>
                          <p class="submenu-para">Intelligent retrieval of info</p>
                        </div>
                      </div>
                    </a>
                    <a routerLink="dockets" class="text-dark">
                      <div class="row dropbox">
                        <div class="col-xl-2 col-lg-2 col-md-0 col-sm-0 p-0">
                          <img
                            src="../../../assets/Image/submenu-icon/dockets-icon.svg"
                            class="img-fluid shadow-1-strong rounded"
                            alt="Skyscrapers"
                          />
                        </div>
                        <div class="col-10 p-0">
                          <h6 class="mb-2 proSubmenu">
                            Dockets
                            <span class="new-item">New</span>
                          </h6>
                          <p class="submenu-para">Organize your information</p>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div class="col-xxl-4 col-xl-4 col-lg-4  col-sm-12 mb-4 mb-md-0 resmargin" style="position: relative;">
                    <div class="bordernone" style="position:absolute;height:100%; width :1px;border-left:1px solid #e9e7e7b3; left: -2px;">
                    </div>
                    <p class="intText" id="int-text-para">Integrations & Apps</p>
                    <a routerLink="tally" class="text-dark">
                      <div class="row dropbox">
                        <div class="col-xl-2 col-lg-2 col-md-0 col-sm-0 p-0">
                          <img
                            src="../../../assets/Image/submenu-icon/integration.svg"
                            class="img-fluid shadow-1-strong rounded"
                            alt="Hollywood Sign on The Hill"
                          />
                        </div>
                        <div class="col-10 p-0">
                          <h6 class="mb-2 proSubmenu">
                            Tally DMS Plugin
                            <span class="new-item">New</span>
                          </h6>
                          <p class="submenu-para">dox2U integrated within Tally</p>
                        </div>
                      </div>
                    </a>
                    <a routerLink="tally" class="text-dark">
                      <div class="row dropbox">
                        <div class="col-xl-2 col-lg-2 col-md-0 col-sm-0 p-0">
                          <img
                            src="../../../assets/Image/submenu-icon/tally-dms-plugin.svg"
                            class="img-fluid shadow-1-strong rounded"
                            alt="Integrations"
                          />
                        </div>
                        <div class="col-10 p-0">
                          <h6 class="mb-2 proSubmenu">
                            Integrations
                            <span class="new-item">New</span>
                          </h6>
                          <p class="submenu-para">dox2U integrated within Tally</p>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
  
            <div  *ngIf="showDropdown && menu.expand === 'dropdown' && menu.name === 'Resources'"  class="dropdown-menu position-absolute megatop resources-menu" aria-labelledby="navbarDropdown">
              <div class="container megaSize">
                <div class="row my-4 " >
                  <div class="col-xxl-6 col-xl-6 col-lg-6  col-sm-12 mb-4 mb-lg-0  resmargin">
                    <!-- <p class="text-uppercase font-weight-bold">
                        Explicabo voluptas
                      </p> -->
                    <a href="https://help.dox2u.com/en" target="_blank" class="text-dark ">
                      <div class="row dropbox">
                        <div class="col-xl-2 col-lg-2 col-md-0 col-sm-0 p-0">
                          <img
                            src="../../../assets/Image/submenu-icon/help-center-nav-icon.svg"
                            class="img-fluid shadow-1-strong rounded"
                            alt=""
                          />
                        </div>
                        <div class="col-10 p-0">
                          <h6 class="mb-2 proSubmenu">Help Center</h6>
                          <p class="submenu-para">
                            Tips, tricks, and hacks for working with your information
                                                          
                          </p>
                        </div>
                      </div>
                    </a>
                    <a href="https://blog.dox2u.com/" target="_blank" class="text-dark">
                      <div class="row dropbox">
                        <div class="col-xl-2 col-lg-2 col-md-0 col-sm-0 p-0">
                          <img
                            src="../../../assets/Image/submenu-icon/dockets-icon.svg"
                            class="img-fluid shadow-1-strong rounded"
                            alt="Los Angeles Skyscrapers"
                          />
                        </div>
                        <div class="col-10 p-0">
                          <h6 class="mb-2 proSubmenu">Blog</h6>
                          <p class="submenu-para">Read our articles on document management.</p>
                        </div>
                      </div>
                    </a>
                    <!-- <a href="" class="text-dark">
                      <div class="row dropbox">
                        <div class="col-2 p-0">
                          <img
                            src="../../../assets/Image/submenu-icon/document-handling-nav-icon.svg"
                            class="img-fluid shadow-1-strong rounded"
                            alt="Skyscrapers"
                          />
                        </div>
                        <div class="col-10 p-0">
                          <h6 class="mb-2 proSubmenu">Security & Reliability</h6>
                          <p class="submenu-para">Bank grade security</p>
                        </div>
                      </div>
                    </a>
                    <a href="" class="text-dark">
                      <div class="row dropbox">
                        <div class="col-2 p-0">
                          <img
                            src="../../../assets/Image/submenu-icon/security-nav-icon.svg"
                            class="img-fluid shadow-1-strong rounded"
                            alt="Skyscrapers"
                          />
                        </div>
                        <div class="col-10 p-0">
                          <h6 class="mb-2 proSubmenu">Smart Cabinet</h6>
                          <p class="submenu-para">Intuitive virtual cabinet</p>
                        </div>
                      </div>
                    </a> -->
                  </div>
                  
                  <div class="col-xxl-6 col-xl-6 col-lg-6  col-sm-12 mb-4 mb-md-0 resmargin borderpartner" (click)="hideDropdown()">
                    <p class="intText">Why dox2U?</p>
                    <a routerLink="problems-we-are-solving" class="text-dark">
                      <div class="row dropbox">
                        <div class="col-xl-2 col-lg-2 col-md-0 col-sm-0 p-0">
                          <img
                            src="../../../assets/Image/problems-nav-icon.svg"
                            class="img-fluid shadow-1-strong rounded"
                            alt="Hollywood Sign on The Hill"
                          />
                        </div>
                        <div class="col-10 p-0">
                          <h6 class="mb-2 proSubmenu">
                       
                          Problems we are solving
                          </h6>
                          <p class="submenu-para">Get rid of siloed, unstructured & scattered information</p>
                        </div>
                      </div>
                    </a>
                    <a routerLink="why-choose-dox2u" class="text-dark">
                      <div class="row dropbox">
                        <div class="col-xl-2 col-lg-2 col-md-0 col-sm-0 p-0">
                          <img
                            src="../../../assets/Image/why-choose-dox2u-nav-icon.svg"
                            class="img-fluid shadow-1-strong rounded"
                            alt="Palm Springs Road"
                          />
                        </div>
                        <div class="col-10 p-0">
                          <h6 class="mb-2 proSubmenu">
                            Why choose dox2U?
                       
                          </h6>
                          <p class="submenu-para">We
                            are simply the best out there!</p>
                        </div>
                      </div>
                    </a>
                    <a routerLink="built-for-everyone" class="text-dark">
                      <div class="row dropbox">
                        <div class="col-xl-2 col-lg-2 col-md-0 col-sm-0 p-0">
                          <img
                            src="../../../assets/Image/accessible-nav-icon.svg"
                            class="img-fluid shadow-1-strong rounded"
                            alt="Palm Springs Road"
                          />
                        </div>
                        <div class="col-10 p-0">
                          <h6 class="mb-2 proSubmenu">
                            What makes us accessible
                       
                          </h6>
                          <p class="submenu-para">Get
                            digital without breaking the bank
                    </p>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
           
          </li>
          <i class="fa-light fa-bars"></i>
        </ul>
        <div class="my-2 my-lg-0  blockRes">
         <a [routerLink]="['/find-workspace']" class="ws-link"> 
          <p class="myDox2U-content ws-image">My dox2U</p>
          
          <button class="button-blank button-transparent my-2 cursor-pointer ipadnone ws-btn" (click)="reloadPage()">My dox2U</button></a>
          <button class="button-primary my-2 my-sm-0 cursor-pointer gs-link"  (click)="scrollToGettingStarted()">
            Get Started
          </button>
        </div>
      </div>
    </nav>
  </div>
  </header> 
  <div class="learn-more-onTop" *ngIf="learnMoreNavigationonPricingPage">
    <p class="mb-0 d-flex"> <img src="../../../assets/Image/speaker.svg" class="speaker-img mr-2"> Introducing dCoins for WhatsApp Messaging. </p>
    <span><button class="learn-btn" (click)="scrollTodCoinsSection()"> Learn more </button></span>
  </div>

  <div class="learn-more-onTop" *ngIf="learnMoreNavigationonTallyPage">
    <p class="mb-0 d-flex"> <img src="../../../assets/Image/speaker.svg" class="speaker-img mr-2"> Introducing secure sharing of documents with Guests over WhatsApp. </p>
    <span><button class="learn-btn" (click)="scrollToExcitingUpdates()"> Learn more </button></span>
  </div>

  <div class="learn-more-onTop" *ngIf="learnMoreNavigationonHomePage">
    <p class="mb-0 d-flex"><img src="../../../assets/Image/speaker.svg" class="speaker-img mr-2">  New exciting updates on dox2U including WhatsApp Integration. </p>
    <span><button class="learn-btn" (click)="scrollToNewFeature()"> Learn more </button></span>
  </div>