import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { bringValue, bringValue_offering, goStatic, icr, textExtractionHero } from 'src/constants/product-content';

@Component({
  selector: 'app-text-extraction',
  templateUrl: './text-extraction.component.html',
  styleUrls: ['./text-extraction.component.scss']
})
export class TextExtractionComponent implements OnInit {
  ngOnInit(): void {
    this.meta.addTag({rel: 'canonical', content: 'https://www.dox2u.com/text-extraction'});
    let scriptBreadcrumbListExtract = this.renderer2.createElement('script');
    scriptBreadcrumbListExtract.type = `application/ld+json`;
    scriptBreadcrumbListExtract.text = `{
      "@context": "https://schema.org/", 
    "@type": "BreadcrumbList", 
    "itemListElement": [{
      "@type": "ListItem", 
      "position": 1, 
      "name": "Home page",
      "item": "https://dox2u.com/"  
    },{
      "@type": "ListItem", 
      "position": 2, 
      "name": "Text Extraction page",
      "item": "https://www.dox2u.com/text-extraction"  
    }]
      }`
    
    this.renderer2.appendChild(this._document.body, scriptBreadcrumbListExtract);
    this.titleService.setTitle("Intelligent Text Extraction Software & Tool with OCR | dox2U, With dox2U's intelligent Text Extraction Software, easily extract text from image or pdf based documents online and work on it at your convenience"); /** For Page Title */
  }

  constructor(private titleService: Title, private meta: Meta, private renderer2: Renderer2, @Inject(DOCUMENT) private _document: Document) {
    /*
      * Cannonical tag
     */
    
  }

  readonly textExtractionText: typeof textExtractionHero  =  textExtractionHero;
  readonly bringValueText: typeof bringValue  = bringValue ;
  readonly goStatic_content: typeof goStatic  = goStatic ;
  readonly bringValueOff: typeof bringValue_offering  = bringValue_offering ;
  readonly icrText: typeof icr  = icr ;
  dox2u_ocr_banner:string="../../../../assets/Image/text-extraction/dox2u_ocr_banner.svg"
  bringValue_img:string="../../../../assets/Image/text-extraction/dox2u_ocr_language.svg"
  ocrDynamic_img:string="../../../../assets/Image/text-extraction/dox2u_ocr_dynamic.svg"
  icr_img:string="../../../../assets/Image/text-extraction/dox2u_ocr_icr.svg"
  handWrite_img:string="../../../../assets/Image/text-extraction/dox2u_hand_writing_recognition.svg"
  
}
