
<section class="features-banner overflow-hidden">
    <div class="container">
      <div class="row">
        <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto">        
              <div class="features-banner-content">
                <h6 class="heading-6 text-uppercase">Features</h6>
                <h1 class="typewriter features-banner-heading">An<span class="typewriter features-banner-heading ">{{ outputText }}</span>platform to <img [src]="group_data" alt="Group data" class="img-fluid group-data"><br>manage all your documents
                </h1>
                  <p class="banner-text">dox2U is a smart document management system that helps manage documented information effortlessly &amp; cost effectively.</p>
                <div class="d-flex justify-content-center" style="margin-bottom: 56px;">
                  <!-- <a href="#" class="me-4"><button class="button-blank">Try for free</button></a> -->
                  <a (click)="scrollToGettingStarted()"> <button class="button-primary">Try for free</button></a>
                </div>
                
                <div class="bannerImg ">
                  <img [src]="feature_img" class="img-fluid d-block img-fluid mx-auto featuresBannerImg " height="276" alt="document management system features">
                </div>  
              </div>          
        </div>
      </div>
    </div>
  </section>
<section class="go-to-solution">
    <div class="container">
      <div class="row">
        <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <div class="go-to-solution-top-text">
            <h2 class="heading-3">Streamlined Document Management</h2>
            <p class="stream-doc">Enhance productivity with instant access to information, conveniently stored in a unified information repository.</p>
          </div> 
        </div>
  
        <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 feature-row-container">
          <div class="row d-flex m-0 feature-row">
  
         
              <div class="features-box">
                <div class="feature-box-content">
                  <img [src]="Workspaces" alt="Workspaces" class="img-fluid">
                  <h4>Workspaces</h4>
                  <p>Workspaces serve as a secure online hub for all your information, providing a collaborative environment just for your team
  </p>
                </div>
              </div>
          
  
       
              <div class="features-box" routerLink="/document-handling">
                <div class="feature-box-content">
                  <img [src]="Document_handling" alt="Document_handling" class="img-fluid">
                  <h4>Document Management
  </h4>
                  <p class="balanced-text">Entrust dox2U with your document handling pains. Effortlessly upload, verify, tag, edit, and distribute your work among users and groups.
                  </p>
  
                </div>
                <div class="go-corner" routerLink="/document-handling">
                  <div class="go-arrow">
                  <img src="../../../../assets/Image/arrow_right_alt.svg" alt="errow" class="img-fluid display-arrow-right">
                  <img src="../../../../assets/Image/arrow_forward.svg" alt="errow" class="img-fluid display-arrow-forward">
                  </div>
                </div>
              </div>
        
  
          
              <div class="features-box">
                <div class="feature-box-content">
                  <img [src]="Plug_and_Play" alt="" class="img-fluid">
                  <h4>Plug-and-Play Convenience</h4>
                  <p class="balanced-text">dox2U presents a ready-to-use cloud solution, simplifying information management for businesses of any size
                  </p>
  
                </div>
              </div>
        
            </div>
            </div>

        
      </div>
    </div>
  </section>

  <section class="productivity-collaboration">
    <div class="container">
      <div class="row">
        <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <div class="go-to-solution-top-text">
            <h2 class="heading-3">Productivity & Collaboration</h2>
            <p class="stream-doc">Streamline information and expedite decision-making by consolidating all work-related documents in one centralized platform.</p>
          </div> 
        </div>
  
        <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 feature-row-container">
          <div class="row d-flex m-0 feature-row">
         <div class="twofeature-box">
              <div class="features-box" (click)="scrollTowhatsappUpdate()">
                <div class="feature-box-content">
                  <div class="d-flex align-items-center justify-content-between">
                  <img [src]="Whatsapp" alt="Whatsapp" class="img-fluid">
                  <span class="go-corner">
                    <div class="go-arrow">
                    <img src="../../../../assets/Image/arrow_right_alt.svg" alt="errow" class="img-fluid display-arrow-right">
                    <img src="../../../../assets/Image/arrow_forward.svg" alt="errow" class="img-fluid display-arrow-forward">
                    </div>
                  </span>
                </div>
                  <h4>WhatsApp Integration</h4>
                </div>
                
              </div>
              <div class="features-box" routerLink="/dockets">
                <div class="feature-box-content">
                  <div class="d-flex align-items-center justify-content-between">
                  <img [src]="Dockets" alt="Dockets" class="img-fluid">
                  <span class="go-corner" routerLink="/document-handling">
                    <div class="go-arrow" routerLink="/dockets">
                    <img src="../../../../assets/Image/arrow_right_alt.svg" alt="errow" class="img-fluid display-arrow-right">
                    <img src="../../../../assets/Image/arrow_forward.svg" alt="errow" class="img-fluid display-arrow-forward">
                    </div>
                  </span>
                  </div>
                  <h4>Dockets</h4>
                </div>
              </div>

            </div>
          
  
       
              
      <div class="features-box" routerLink="/guest-access">
        <div class="feature-box-content">
          <img [src]="Guest_access" alt="" class="img-fluid">
          <h4>Guest access</h4>
          <p>Sharing docs with external partners and customers has never been easier. Say goodbye to siloed information over emails.</p>
        
        </div>
        <div class="go-corner" routerLink="/guest-access">
          <div class="go-arrow">
            <img src="../../../../assets/Image/arrow_right_alt.svg" alt="errow" class="img-fluid display-arrow-right">
            <img src="../../../../assets/Image/arrow_forward.svg" alt="errow" class="img-fluid display-arrow-forward">
          </div>
        </div>
      </div>
  


      <div class="features-box" routerLink="/tally">
        <div class="feature-box-content">
          <img [src]="Integration_with_tally" alt="" class="img-fluid">
          <h4>Integration with Tally</h4>
          <p class="balanced-text">Get rid of paper-pains while managing your critical Accounts & Financial documents by linking your Tally with dox2U.

          </p>

        </div>
        <div class="go-corner" routerLink="/tally">
          <div class="go-arrow">
            <img src="../../../../assets/Image/arrow_right_alt.svg" alt="errow" class="img-fluid display-arrow-right">
            <img src="../../../../assets/Image/arrow_forward.svg" alt="errow" class="img-fluid display-arrow-forward">
          </div>
        </div>
      </div>
        
            </div>
            </div>

        
      </div>
    </div>
  </section>
  
<section id="doc-preview" class="doc-preview common-padd">
    <div class="container">
      <div class="row">
        <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <div class="doc-preview-header">
            <h2 class="heading-3">Smart Retrieval</h2>
            <p class="stream-doc text-center">Find information instantly when you need it the most with just a few clicks using dox2U’s intelligent organization & retrieval options</p>
          </div> 
          <div class="features-box doc-preview-card-container" routerLink="/smart-cabinet">
            <img src="../../../../assets/Image/feature/smart-retrieval-cabinet.svg" class="cabinet-default-image img-fluid">
            <img src="../../../../assets/Image/feature/smart-retrieval-cabinet-hover.svg" class="cabinet-hover-image img-fluid">
            <img src="../../../../assets/Image/feature/smart-retrieval-cabinet-resp.svg" class="cabinet-default-image-resp">
            <div class="doc-preview-box">
              <div class="feature-box-content">
                <div class="d-flex align-items-center justify-content-between">
                  <h4>Cabinet</h4>
                <span class="go-corner" routerLink="/smart-cabinet">
                  <div class="go-arrow">
                  <img src="../../../../assets/Image/arrow_right_alt.svg" alt="errow" class="img-fluid display-arrow-right mb-0">
                  <img src="../../../../assets/Image/arrow_forward.svg" alt="errow" class="img-fluid display-arrow-forward mb-0">
                  </div>
                </span>
              </div>
              <p class="set-width">Your information remains tagged and categorized according to your preferences, ensuring immediate access when required.</p>

               
              </div>
              
            </div>

          </div>

          <div class="features-box doc-preview-card-container" routerLink="/text-extraction">
            <img src="../../../../assets/Image/feature/smart-retrieval-cabinet.svg" class="cabinet-default-image img-fluid">
            <img src="../../../../assets/Image/feature/smart-retrieval-extract-hover.svg" class="cabinet-hover-image img-fluid">
            <img src="../../../../assets/Image/feature/smart-retrieval-extract-resp.svg" class="cabinet-default-image-resp">
            <div class="doc-preview-box">
              <div class="feature-box-content">
                <div class="d-flex align-items-center justify-content-between">
                  <h4>Text Extraction</h4>
                <span class="go-corner" routerLink="/text-extraction">
                  <div class="go-arrow">
                  <img src="../../../../assets/Image/arrow_right_alt.svg" alt="errow" class="img-fluid display-arrow-right mb-0">
                  <img src="../../../../assets/Image/arrow_forward.svg" alt="errow" class="img-fluid display-arrow-forward mb-0">
                  </div>
                </span>
              </div>
              <p class="set-width">Leverage OCR/ICR-powered intelligent text extraction from diverse documents, enabling precise information retrieval even from the smallest content fragments.</p>

               
              </div>
              
            </div>

          </div>

          <div class="features-box doc-preview-card-container" routerLink="/deep-search">
            <img src="../../../../assets/Image/feature/smart-retrieval-cabinet.svg" class="cabinet-default-image img-fluid">
            <img src="../../../../assets/Image/feature/smart-retrieval-search-hover.svg" class="cabinet-hover-image img-fluid">
            <img src="../../../../assets/Image/feature/smart-retrieval-search-resp.svg" class="cabinet-default-image-resp">
            <div class="doc-preview-box">
              <div class="feature-box-content">
                <div class="d-flex align-items-center justify-content-between">
                  <h4>Deep Search</h4>
                <span class="go-corner" routerLink="/deep-search">
                  <div class="go-arrow">
                  <img src="../../../../assets/Image/arrow_right_alt.svg" alt="errow" class="img-fluid display-arrow-right mb-0">
                  <img src="../../../../assets/Image/arrow_forward.svg" alt="errow" class="img-fluid display-arrow-forward mb-0">
                  </div>
                </span>
              </div>
              <p class="set-width">Effortlessly find your documents with our robust search powered by a unique algorithm that combines name, tags, and content.</p>

               
              </div>
              
            </div>

          </div>
        </div>
      </div>
    </div>
  </section>

<section class="new-features">
  <div class="container">
    <div class="row">
      <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <div class="solutions-fall-fat-content mx-auto">
          <img src="../../../../assets/Image/feature/unique-dots.svg" class="unique-dots">
      <h6 class="heading-6 text-uppercase">New Features</h6>
      <p class="features-banner-heading">Exciting dox2U upgrades to help you work better!</p>
    </div>
      </div>
    </div>
  </div>

  <!-- <app-insert-image-description class="change-bg-whatsapp" id="whatsappIntegrationText"
  [contentDescribeImage]="whatsappIntegrationText"
  [imgSrc]="whatsappIntegrationImg"
  [videoUrl]="videoURL"
  ></app-insert-image-description> -->

  <div class="container productLeft overflow-hidden change-bg-whatsapp" id="whatsappIntegrationText">
    <div class="">
      <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto image-description">
        <div class="row d-flex align-items-center row-direction p-3">

          <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 d-flex align-items-center flex-wrap content-feature">
            <div class="workspace-banner-content document-handling-banner-content image-content">
              <h6 class="heading-6 text-uppercase">WhatsApp Integration</h6>
              <h1 class="heading-1">One click sharing on WhatsApp</h1>
              <p class="common-para">dox2U seamlessly integrates with WhatsApp for secure document sharing through secure links, controlled by OTPs and duration settings. No complex setup—just add <a class="dcoins-hover" title="WhatsApp being a paid service requires you to add dCoins to your dox2U wallet"> dCoins </a> to your wallet and start sharing instantly. Receive status updates on document delivery or reading.</p>

            </div>
          </div>

          <!-- <div class="col-xxl-1 d-xl-none d-xxl-block d-lg-none d-md-none d-sm-none"></div> -->

          <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center">

            <img src={{whatsappIntegrationImg}} alt="document handling system" class="img-fluid img-size">

            <video #myVideo id="myVideo" width="803" height="452" controls>
              <source src={{videoURL}} type="video/mp4">
        Your browser does not support the video tag.
            </video>

          </div>
        </div>
      </div>
    </div>
  </div>

  <app-insert-image-description class="change-bg-docviewer" id="docViewerText"
  [contentDescribeImage]="docViewerText"
  [imgSrc]="docViewerImg"
  [videoUrl]="videoURL1"
  ></app-insert-image-description>

 </section>
 <section class="feature-comparision common-padd  bottmp">
  <div class="container">
      <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-10 col-xl-10 col-xxl-10 mx-auto">
              <div class="row">
                  <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                      <div class="feature-comparision-top d-flex flex-column align-items-center mb-4 pb-3">
                        <h6 class="heading-6 text-uppercase mb-0">Frequently asked questions</h6>
                          <h1 class="features-banner-heading mb-0">Learn more about dox2U</h1>
                      </div>
                      <div class="feature-comparision-Acordian">
                          <div id="accordion" class="accordion">
                          <div class="acc-section">
                              <div class="feature-comparision-Acordian-Item d-flex justify-content-between collapsed" data-toggle="collapse" href="#collapseOne" >
                                  <h4 class="accordian-headings mb-0" style="color: rgb(88, 88, 88);">Why should my business use dox2U? </h4>
                                  <img src="../../../assets/Image/plus.svg" class="plus-img">
                                  <img src="../../../assets/Image/minus.svg" class="minus-img">
                              </div>
                              <div id="collapseOne" class="feature-comparision-Acordian-Body collapse" data-parent="#accordion">
                                  <p>dox2U is a new way for you to manage your documented information. It replaces paper with a cloud-based solution that ensures quick access and efficient collaboration. Instead of your data being locked on paper, dox2U uses intelligent capabilities to  convert data from both text and image-based documents, enhancing work convenience. With a powerful search algorithm that works on content, you can find the information you need within seconds. With ready-to-use workspaces and built-in flexibilities to adapt to your processes, dox2U can become a centralized repository of information for your company. When all your information is stored in one location, everyone can make quicker and better decisions. 

                                  </p>
                              </div>
                          </div>

                          <div class="acc-section">
                              <div class="feature-comparision-Acordian-Item d-flex justify-content-between collapsed" data-toggle="collapse" href="#collapseTwo">
                                  <h4 class="accordian-headings mb-0">What pains does dox2U solve for us?                                  </h4>
                                  <img src="../../../assets/Image/plus.svg" class="plus-img">
                                  <img src="../../../assets/Image/minus.svg" class="minus-img">
                              </div>
                              <div id="collapseTwo" class="feature-comparision-Acordian-Body collapse" data-parent="#accordion">
                                  <p>With dox2U, you will experience a transformative shift in your document management, where efficiency, organization, and overall workflow effectiveness are significantly improved in a secure and user-friendly manner.
                                    </p>
                                  
                                    <h5 class="acc-sub-heading">
                                      Go Paperless</h5>
                                   <p> dox2U tackles the inherent challenges of handling and storing paper, providing a comprehensive solution to alleviate the associated pains. Bid farewell to physical paper clutter as we guide you through a seamless transition to a digital environment, mitigating the persistent risk of losing critical documents. 
                                  </p> 
                                  <h5 class="acc-sub-heading">
                                    Work Smarter</h5>
                                    <p>
                                    Our platform, designed with an intuitive interface, facilitates swift and precise document retrieval, a stark contrast to the time-consuming process of manually managing and searching through paper files. 
                                  </p>
                                  <h5 class="acc-sub-heading">
                                    Secure & Reliable </h5>
                                    <p> 
                                    Prioritizing security, dox2U addresses concerns of unauthorized access by implementing robust encryption and real-time backups. 
                                    
                                  </p>

                              </div>
                          </div>

                          <div class="acc-section">
                              <div class="feature-comparision-Acordian-Item d-flex justify-content-between collapsed" data-toggle="collapse" href="#collapseSeven">
                                  <h4 class="accordian-headings mb-0">How does WhatsApp sharing work?</h4>
                                  <img src="../../../assets/Image/plus.svg" class="plus-img">
                                  <img src="../../../assets/Image/minus.svg" class="minus-img">
                              </div>
                              <div id="collapseSeven" class="feature-comparision-Acordian-Body collapse" data-parent="#accordion">
                                  <p>dox2U simplifies document sharing on WhatsApp by offering a seamless integration. To get started, add dCoins to your wallet, as WhatsApp sharing is a paid service. Once your wallet is funded, share documents securely through WhatsApp using unique links accessible with OTPs (One-Time Passwords) and duration controls. This ensures that your shared documents remain secure and accessible only to the intended recipients for a specified period. Receive status updates on the delivery or reading of the shared document, providing real-time insights. It's a hassle-free process designed to enhance your document-sharing experience on WhatsApp, making collaboration efficient and secure.

                                  </p>

                              </div>
                          </div>

                          <div class="acc-section">
                              <div class="feature-comparision-Acordian-Item d-flex justify-content-between collapsed" data-toggle="collapse" href="#collapseThree">
                                  <h4 class="accordian-headings mb-0">How will dox2U integrate with my Tally?
                                  </h4>
                                  <img src="../../../assets/Image/plus.svg" class="plus-img">
                                  <img src="../../../assets/Image/minus.svg" class="minus-img">
                              </div>
                              <div id="collapseThree" class="feature-comparision-Acordian-Body collapse" data-parent="#accordion">
                                  <p>dox2U seamlessly integrates with Tally to enhance your document management experience. With our DIY Tally Plugin, setting up this integration is just a matter of a few clicks. This integration allows you to upload, manage, view, and share your accounts and financial documents efficiently from within Tally itself. For each document attached to a Tally Voucher/Master, a unique URL is embedded in Tally, providing complete portability to access this document from anywhere, anytime. Critical information about your Tally transactions is captured and tagged with your documents, allowing quick and instant retrieval using our Tally Cabinet. Our Tally integration not only streamlines your accounts and financial records but also contributes to a more organized and efficient workflow. Stay tuned for upcoming features as we continuously work to enhance the integration and provide a seamless experience.
                                  </p>
                              </div>
                          </div>

                          <div class="acc-section">
                              <div class="feature-comparision-Acordian-Item d-flex justify-content-between collapsed" data-toggle="collapse" href="#collapseFour">
                                  <h4 class="accordian-headings mb-0">Can I integrate dox2U with any other solution used by me?
                                  </h4>
                                      <img src="../../../assets/Image/plus.svg" class="plus-img">
                                      <img src="../../../assets/Image/minus.svg" class="minus-img">
                              </div>
                              <div id="collapseFour" class="feature-comparision-Acordian-Body collapse" data-parent="#accordion">
                                  <p>Yes, dox2U is designed with flexibility in mind, allowing integration with various solutions used by your business. Our platform supports APIs (Application Programming Interfaces), enabling seamless integration with other software and systems. This versatility ensures that dox2U can complement and enhance your existing workflow. If you have specific integration requirements or would like to explore the possibilities, please contact our support team. We are dedicated to providing a tailored and integrated experience to meet your business needs.

                                  </p>

                              </div>
                          </div>

                          <div class="acc-section">
                              <div class="feature-comparision-Acordian-Item d-flex justify-content-between collapsed" data-toggle="collapse" href="#collapseFive">
                                  <h4 class="accordian-headings mb-0">How much does it cost to get dox2U up and running for me?

                                  </h4>
                                  <img src="../../../assets/Image/plus.svg" class="plus-img">
                                  <img src="../../../assets/Image/minus.svg" class="minus-img">
                              </div>
                              <div id="collapseFive" class="feature-comparision-Acordian-Body collapse" data-parent="#accordion">
                                  <p>dox2U offers a hassle-free, ready-to-use solution with no upfront investments required. You can get started with dox2U against a nominal subscription charge, making it a cost-effective choice for businesses of all sizes. With various plan choices available, you can select the one that best suits your specific needs. This subscription model provides you with access to all the features and functionalities, ensuring a comprehensive document management experience without the burden of significant initial costs. The straightforward pricing structure allows you to leverage dox2U's benefits with ease, making it a flexible and affordable solution for your document management needs.
                                  </p>                                  
                              </div>
                          </div>

                          <div class="acc-section">
                              <div id="collapseSix1" class="feature-comparision-Acordian-Item d-flex justify-content-between collapsed" data-toggle="collapse" href="#collapseSix">
                                  <h4 class="accordian-headings mb-0"> Is my information secure with dox2U?
                                  </h4>
                                  <img src="../../../assets/Image/plus.svg" class="plus-img">
                                  <img src="../../../assets/Image/minus.svg" class="minus-img">
                              </div>
                              <div id="collapseSix" class="feature-comparision-Acordian-Body collapse" data-parent="#accordion">
                                  <p>Yes, ensuring the security of your information is a top priority for dox2U. Our platform employs robust encryption protocols to safeguard your data during transmission and storage. Additionally, our cloud infrastructure is designed with the latest security features to ensure a safe environment for your documents. We take data redundancy seriously, with multiple real-time backups in place, providing an extra layer of protection against any unforeseen events. We continuously update and monitor our security practices to stay ahead of potential threats. Rest assured, your information is handled with the utmost care and security when you choose dox2U. 
                                   <a class="learnmore" target="_blank" routerLink="/security"> Learn More </a>
                                 </p>
                              </div>
                          </div>
                      </div>
                  </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</section>

<section class="getstart_color">
    <app-getting-started></app-getting-started>
</section>

<app-gradient-divider></app-gradient-divider>

  