<app-dox2-u-for-tally></app-dox2-u-for-tally>

<!-- 
<section class="home-banner overflow-hidden">
    <div class="container-fluid">
        <div class="row">
            <div class="col-xxl-10 col-xl-10 col-lg-12 col-md-12 col-sm-12 mx-auto">

                <div class="row d-flex align-items-center">
                    <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 d-flex justify-content-center hero-container">
                        
                        <div class="home-banner-content">
                            <h6 class="heading-6 tallyfont">Tally DMS</h6>
                            <h1 class="heading-1">Document <span class="underline-highlight">Management </span>System For Tally</h1>
                            
                            <div class="hero-text-align">
                                <div class="d-flex textleft  lineheight28px">
                                    <li></li>
                                    <p class=" tally-align fontsize24px">
                                        Integrated with Tally ERP 9 & Tally Prime</p>
                                </div>
                                <div class="d-flex textleft lineheight28px">
                                    <li></li>
                                    <p class=" tally-align fontsize24px">Manage Accounting & Financial documents digitally</p>
                                </div>
                                <div class="d-flex textleft lineheight28px">
                                    <li></li>
                                    <p class="tally-align fontsize24px">Go paperless and store on cloud</p>
                                </div>
                                <div class="d-flex textleft lineheight28px">
                                    <li></li>
                                    <p class=" tally-align fontsize24px">Access documents anytime, anywhere</p>
                                </div>
                            </div>
                     

                            <div class="d-flex home-banner-btns">
                                <a><button class="button-primary trydox cursor-pointer" (click)="scrollToDownloadTallyForm()">Try
                                        dox2U</button></a> &nbsp;
                                <a href="https://calendly.com/dox2u-dms/dox2u-demo?month=2023-04" target="_blank" class="underlineNone"><button
                                        class="button-transparent btn-schedule d-flex align-items-center"><img src="../../../assets/Image/sche.svg"
                                            alt=""> Schedule a demo</button></a>
                            </div>
                        </div>
                    </div>

                    <div class="col-xxl-7 col-xl-7 col-lg-7 col-md-12 col-sm-12 ">
                        <div class="home-banner-hero-img d-flex " data-aos="fade-left" data-aos-duration="2500">
                            <img src="../../../assets/Image/tally-dms.svg" alt="document management system" class="img-fluid"
                                loading="lazy">
                        </div>
                    </div>
                    <!-- <div class="col-xxl-1"></div> -->
                <!-- </div>

            </div>
        </div>
    </div>
</section>
<div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 p-2 manage d-flex justify-content-center ">
    <p class="businessfont  font992"> Used by more than 10,000 businesses to manage their documents better</p>
</div>

<section class="banner-new">
    <div class="container">
    <div class="row">
        <div class=" col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto">
            <div class="tabs-section-Tbar ">
                <h3 class="size-fourty mb-3">How it works?</h3>
                <h4 class="heading-4">TALLY CLOUD DMS</h4>
                <p class="common-para">Getting started with dox2U’s Tally DMS Add-on is simple</p>
            </div>
    <div class="row banner-new-container">
        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12">
            <div class="integration-doc">
                <iframe style="width:86%; height:318px;z-index:3" class="iframeCSS"
                    src="https://youtube.com/embed/sxPL6es7oQM">
                </iframe>
            </div>
        </div>
        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 integrate-info-list">
            <div class="integrate-doc integrate-para">
                <p class="common-para1 mb-3 pb-1 "><img src="../../../assets/Image/how.svg" class=" mright">Download dox2U Tally Plugin</p>
                <p class="common-para1 mb-3 pb-1"> <img src="../../../assets/Image/how.svg"  class=" mright">Install on machine where you run Tally (even on cloud)</p>
                <p class="common-para1 mb-3 pb-1"> <img src="../../../assets/Image/how.svg"  class=" mright">Create your dox2U workspace and sign-in</p>
                <p class="common-para1 mb-3 pb-1"> <img src="../../../assets/Image/how.svg"  class=" mright">Attach documents in Tally</p>
                <p class="common-para1 mb-3 pb-1"> <img src="../../../assets/Image/how.svg"  class=" mright">View from anywhere</p>
                
            </div>
        </div>
    </div>
        </div>
    </div>
    
    </div>
    
    </section>
    <div class="gradient-divider"></div>

    <section id="downloadForm" class="download-and-install top-padd bottom-padd common-padd ">
        <div class="container" style="width:970px">
            <div class="row">
    
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto">
                    <div class="row d-flex align-items-center justify-content-between">
                        <div
                            class="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 d-flex flex-column justify-content-start t-cen centText mb-5">
    
                            <h3 class="heading-3 ">Download and install dox2U’s Tally DMS Add-on now!</h3>
                            <div>
                                <div class="d-flex ">
                                    <img src="../../../assets/Image/dcheck.svg" class="mright mb-3 pb-1">
                                    <p class="common-para wunset">15 days Free Trial</p>
                                </div>
                                <div class="d-flex ">
                                    <img src="../../../assets/Image/dcheck.svg" class="mright mb-3 pb-1">
                                    <p class="common-para wunset">No credit card needed</p>
                                </div>
                                <div class="d-flex ">
                                    <img src="../../../assets/Image/dcheck.svg" class="mright mb-3 pb-1">
                                    <p class="common-para wunset">Simple installation & activation</p>
                                </div>
                                <div class="d-flex ">
                                    <img src="../../../assets/Image/dcheck.svg" class="mright mb-3 pb-1">
                                    <p class="common-para wunset">Secure dox2U cloud DMS</p>
                                </div>
                            </div>
    
                            <!-- </div> -->
                            <!--<p class="itelictext-adon "><i class=" windo-10">Supports Tally ERP 9, Tally Prime (Single and
                                    Multi
                                    User) and Tally on Cloud</i>
                            </p>
                        </div>
                        <div class="col-xxl-1 d-xl-none d-xxl-block d-lg-none d-md-none d-sm-none"></div>
                        <div
                            class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 d-flex flex-column justify-content-center martopD">
    
                            <div id="tallyDMSshow" *ngIf="!submittedForm">
                                <form [formGroup]="dmsForm" (ngSubmit)="onSubmit()">
                                    <div class="d-flex flex-column colmG">
                                        <div class="d-flex gap26 w-100">
                                            <div class="d-flex flex-column w-50 input-container">
                                                <div class="nameInput d-flex flex-column form-group">
                                                    <label>
                                                        <p class="label-field mb-1 tunset">First Name
                                                        </p>
                                                    </label>
                                                    <input class="input-border-none form-control" formControlName="firstname" id="firstname" name="firstname" (keypress)="resetFirstNameDiv($event)">
                                                    
                                                        <!-- <div *ngIf="dmsForm.get('firstname')?.hasError('required') && dmsForm.get('firstname').touched" class="error">This field cannot be empty</div> -->
                                              <!----  </div>
                                                <p class="mb-0 meserr text-danger" *ngIf="firstNameValidDiv">
                                                    This field cannot be empty
                                                  </p>
                                                  
                                                <p id="errfirstname1" class="mb-0  meserr text-danger"></p>
                                            </div>
                                            <div class="d-flex flex-column w-50 input-container">
                                                <div class="nameInput d-flex flex-column form-group">
                                                    <label>
                                                        <p class="label-field mb-1 tunset">Last Name<span class="text-danger"
                                                                >*</span></p>
                                                    </label>
                                                    <input class="input-border-none form-control" formControlName="lastname" id="lastname" name="lastname" (keypress)="resetLastNameDiv($event)">
                                                </div>
                                                <p class="mb-0 meserr text-danger" *ngIf="lastNameValidDiv">
                                                    This field cannot be empty
                                                  </p>
                                                <p id="errlastname1" class="mb-0  meserr"></p>
                                            </div>
                                        </div>
                                        <div class="d-flex gap26 w-100">
    
                                            <div class="d-flex flex-column w-50 input-container">
                                                <div class="nameInput d-flex flex-column form-group">
                                                    <label>
                                                        <p class="label-field mb-1 tunset">Email<span
                                                                style="color:red">*</span>
                                                        </p>
                                                    </label>
                                                    <input class="input-border-none form-control" formControlName="email" id="tallyemail" name="tallyemail" (keypress)="resetEmailDiv($event)">
                                                </div>
                                                <p class="mb-0 meserr text-danger" *ngIf="emailValidDivEmpty">
                                                    This field cannot be empty
                                                  </p>
                                                  <p class="mb-0 meserr text-danger" *ngIf="emailValidDivInvalid">
                                                    Please enter valid email
                                                  </p>
                                                  
                                               
                                            </div>
                                            <div class="d-flex flex-column w-50 input-container">
                                                <div class="nameInput d-flex flex-column form-group">
                                                    <label class="mb-0">
                                                        <p class="label-field mb-0 tunset">Phone No.</p>
                                                    </label>

                                                    <input name="phone" type="tel" id="phoneC" placeholder=""
                                                        class="getting-started-input phn-inp input-border-none inp100 form-control" formControlName="phone" (keypress)="resetPhoneDiv($event)"
                                                        
                                                        maxlength="15">
                                                </div>
                                                <p class="mb-0 meserr text-danger" *ngIf="phoneValidDiv">
                                                    This field cannot be empty
                                                  </p>
    
                                            </div>
                                        </div>
                                        <div class="justify-content-center align-items-center d-flex">
                                            <button class="button-primary trydox" id="myButton" type="submit" [disabled]="isLoading"><span
                                                    class="hideDownload">Download</span> </button>
                                            <div id="loader" class="spinner-border text-white"
                                                style="display:none;position:absolute;" role="status">
                                                <span class="sr-only"></span>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div id="tallyDMShide" *ngIf="submittedForm" class="d-flex  flex-column justify-content-center align-items-center"
                                >
                                <p class="common-para text-center">Thank you! We’ve sent you an email with a link for
                                    downloading our Tally Add-on.</p>
                                <img src="../../../assets/Image/tallysuccess.svg">
                            </div>
    
                            <i class="textbot  sales-support">For Sales & Support :
                                +91
                                8448857421 </i>
    
                        </div>
    
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="physical-cabinets top-padd  common-padd cloudManagement ">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-xxl-12">
                    <div class="solutions-fall-fat-content whyBusiness-font mx-auto mb-0">
                        <h6 class="heading-6">CLOUD DOCUMENT MANAGEMENT SYSTEM FOR TALLY</h6>
                        <h2 class="heading-2 wmargin6">Why Businesses choose dox2U’s Tally Plugin?
                        </h2>
                    </div>
                </div>
            </div>
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto infoContainer">

                <div class="row d-flex align-items-center  ">


                    <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex startposition martop">
                        <img [src]="imagePathList" alt="dox2U provides a digital cabinet to organize all your documents easily" class="img-fluid aos-init aos-animate" data-aos="fade-right" data-aos-duration="2500">
                    </div>
    
                    <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex justify-content-start ">
                        <div class="retrieval-as-easy">
                            <!-- <h6 class="heading-6">STEP 2</h6> -->
                          <!----  <h3 class="heading-3">Easy one-click access to documents</h3>
                            <div>
                                <div class="d-flex textleft">
                                    <li></li>
                                    <p class="common-para tally-align">Effortlessly link and view documents related to Tally
                                        transactions and masters</p>
                                </div>
                                <div class="d-flex textleft">
                                    <li></li>
                                    <p class="common-para tally-align">Stop dependency on accountants or manual document
                                        searches</p>
                                </div>
                                <div class="d-flex textleft">
                                    <li></li>
                                    <p class="common-para tally-align">Convenience of having all important documents readily
                                        available at your fingertips</p>
                                </div>
    
                            </div>
                        </div>
                    </div>
    
                </div>
           
            </div>
        </div>
   
    </section>
    <section class="physical-cabinets top-padd  common-padd ">
        <div class="container">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto infoContainer">
                <div class="row d-flex align-items-center  small-screens">
                    <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex justify-content-start ">
                        <div class="retrieval-as-easy">
                            <!-- <h6 class="heading-6">STEP 2</h6> -->
                        <!----    <h3 class="heading-3">Stop printing &amp; filing</h3>
                            <div>
                                <div class="d-flex textleft">
                                    <li></li>
                                    <p class="common-para tally-align">Create a <b>digital vault </b> for all your
                                        financial records and stop storage on paper</p>
                                </div>
                                <div class="d-flex textleft">
                                    <li></li>
                                    <p class="common-para tally-align">Store documents on secure cloud for as many years
                                        to meet audit compliances</p>
                                </div>
                                <div class="d-flex textleft">
                                    <li></li>
                                    <p class="common-para tally-align">Easily find and retrieve organized documents</p>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex endposition martop">
                        <img [src]="imagePathList1" alt="create workspace" class="img-fluid drop-shadow aos-init aos-animate" data-aos="fade-left" data-aos-duration="2500">
                    </div>

                </div>
            </div>
        </div>
   
    </section>
    <section class="physical-cabinets top-padd  common-padd ">
        <div class="container">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto infoContainer">
                
                <div class="row d-flex align-items-center  ">


                    <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex startposition martop">
                        <img [src]="imagePathList2" alt="dox2U provides a digital cabinet to organize all your documents easily" class="img-fluid aos-init aos-animate" data-aos="fade-right" data-aos-duration="2500">
                    </div>

                    <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex justify-content-start ">
                        <div class="retrieval-as-easy">
                            <!-- <h6 class="heading-6">STEP 2</h6> -->
                       <!----     <h3 class="heading-3">Anytime, anywhere information</h3>
                            <div>
                                <div class="d-flex textleft">
                                    <li></li>
                                    <p class="common-para tally-align">Instantly access and retrieve your documents
                                        anytime, anywhere</p>
                                </div>
                                <div class="d-flex textleft">
                                    <li></li>
                                    <p class="common-para tally-align">Enhance collaboration and communication by easily
                                        sharing relevant documents with colleagues or clients</p>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
   
    </section>

    <section class="physical-cabinets top-padd  common-padd ">
        <div class="container">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto infoContainer">
                <div class="row d-flex align-items-center  small-screens">
                    <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex justify-content-start ">
                        <div class="retrieval-as-easy">
                            <!-- <h6 class="heading-6">STEP 2</h6> -->
                          <!----  <h3 class="heading-3">Security and protection of documents</h3>
                            <div>
                                <div class="d-flex textleft">
                                    <li></li>
                                    <p class="common-para tally-align">Experience peace of mind knowing that your
                                        documents are securely stored on cloud and backed up</p>
                                </div>
                                <div class="d-flex textleft">
                                    <li></li>
                                    <p class="common-para tally-align">Documents are protected against theft, physical
                                        damage, or natural disasters</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex endposition martop">
                        <img [src]="imagePathList3" alt="create workspace" class="img-fluid drop-shadow aos-init aos-animate" data-aos="fade-left" data-aos-duration="2500">
                    </div>

                </div>
         
            </div>
        </div>
   
    </section>



    <section class="physical-cabinets top-padd  common-padd ">
        <div class="container">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto infoContainer">
                <div class="row d-flex align-items-center  ">


                    <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex startposition martop">
                        <img [src]="imagePathList4" alt="dox2U provides a digital cabinet to organize all your documents easily" class="img-fluid aos-init aos-animate" data-aos="fade-right" data-aos-duration="2500">
                    </div>

                    <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex justify-content-start ">
                        <div class="retrieval-as-easy">
                            <!-- <h6 class="heading-6">STEP 2</h6> -->
                    <!----        <h3 class="heading-3">Go digital. Get Paperless. Work smarter</h3>
                            <div>
                                <div class="d-flex textleft">
                                    <li></li>
                                    <p class="common-para tally-align">Increase productivity and save time by
                                        efficiently managing and organizing your documents within Tally</p>
                                </div>
                                <div class="d-flex textleft">
                                    <li></li>
                                    <p class="common-para tally-align">Optimize workflow and decision-making by having
                                        all necessary documents readily available for analysis and planning.</p>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
   
    </section>
    <section class="physical-cabinets top-padd  common-padd ">
        <div class="container">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto infoContainer">
                
                <div class="row d-flex align-items-center  small-screens">
                    <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex justify-content-start ">
                        <div class="retrieval-as-easy">
                            <!-- <h6 class="heading-6">STEP 2</h6> -->
                <!----            <h3 class="heading-3">Reduce cost</h3>
                            <div>
                                <div class="d-flex textleft">
                                    <li></li>
                                    <p class="common-para tally-align">Effortlessly integrate cloud DMS into your
                                        existing Tally setup, without any complex configurations or installations.</p>
                                </div>
                                <div class="d-flex textleft">
                                    <li></li>
                                    <p class="common-para tally-align">Streamline document-related processes, minimizing
                                        errors and costly mistakes.</p>
                                </div>
                                <div class="d-flex textleft">
                                    <li></li>
                                    <p class="common-para tally-align">Avoid the expenses associated with physical
                                        document storage, such as printing, paper, and filing cabinets.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex endposition martop">
                        <img [src]="imagePathList5" alt="create workspace" class="img-fluid drop-shadow aos-init aos-animate" data-aos="fade-left" data-aos-duration="2500">
                    </div>

                </div>
            </div>
        </div>
   
    </section>
    <app-tally-management-plugin></app-tally-management-plugin>

    <app-eightcards-document></app-eightcards-document>

    <app-partner-with-us></app-partner-with-us>

    <section class="feature-comparision common-padd  bottmp">
        <div class="container">
            <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-10 col-xl-10 col-xxl-10 mx-auto">
                    <div class="row">
                        <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                            <div class="feature-comparision-top d-flex flex-column align-items-center mb-5 pb-3">
                                <h2 class="mb-3"><b>Frequently asked questions</b></h2>
                            </div>
                            <div class="feature-comparision-Acordian">
                                <div id="accordion" class="accordion">
                                <div class="acc-section">
                                    <div class="feature-comparision-Acordian-Item d-flex justify-content-between collapsed" data-toggle="collapse" href="#collapseOne">
                                        <h4 class="accordian-headings mb-0" style="color: rgb(88, 88, 88);">What is a Document Management System? </h4>
                                        <img src="../../../assets/Image/plus.svg" class="plus-img">
                                        <img src="../../../assets/Image/minus.svg" class="minus-img">
                                    </div>
                                    <div id="collapseOne" class="feature-comparision-Acordian-Body collapse" data-parent="#accordion" >
                                        <p>A document management system (DMS) is a software solution that enables organizations to store, organize, manage, and track their digital documents and files in a centralized and secure manner. It provides a structured approach to document management, allowing users to create, capture, store, retrieve, and collaborate on documents efficiently.
                                        </p>
                                    </div>
                                </div>
    
                                <div class="acc-section">
                                    <div class="feature-comparision-Acordian-Item d-flex justify-content-between collapsed" data-toggle="collapse" href="#collapseTwo">
                                        <h4 class="accordian-headings mb-0">What is the purpose of the dox2U Tally Plugin?</h4>
                                        <img src="../../../assets/Image/plus.svg" class="plus-img">
                                       <img src="../../../assets/Image/minus.svg" class="minus-img">
                                    </div>
                                    <div id="collapseTwo" class="feature-comparision-Acordian-Body collapse" data-parent="#accordion">
                                        <p>dox2U’s Tally Plugin is designed to enhance the functionality of Tally accounting software by providing additional features for Document Management and integrates Tally with our award-winning cloud based Document Management System. 
                                        </p>
    
                                    </div>
                                </div>
    
                                <div class="acc-section">
                                    <div class="feature-comparision-Acordian-Item d-flex justify-content-between collapsed" data-toggle="collapse" href="#collapseThree">
                                        <h4 class="accordian-headings mb-0">How does dox2U’s Tally DMS Plugin integrate with Tally accounting software?</h4>
                                        <img src="../../../assets/Image/plus.svg" class="plus-img">
                                        <img src="../../../assets/Image/minus.svg" class="minus-img">
                                    </div>
                                    <div id="collapseThree" class="feature-comparision-Acordian-Body collapse" data-parent="#accordion">
                                        <p>dox2U’s Tally Plugin integrates seamlessly with Tally accounting software through a simple installation process. Once installed, it adds features and functionalities to directly attach & view documents within the Tally interface..</p>
                                    </div>
                                </div>
    
                                <div class="acc-section">
                                    <div class="feature-comparision-Acordian-Item d-flex justify-content-between collapsed" data-toggle="collapse" href="#collapseFour">
                                        <h4 class="accordian-headings mb-0">What features and functionalities does dox2U’s Tally cloud DMS offer?
                                        </h4>
                                        <img src="../../../assets/Image/plus.svg" class="plus-img">
                                        <img src="../../../assets/Image/minus.svg" class="minus-img">
                                    </div>
                                    <div id="collapseFour" class="feature-comparision-Acordian-Body collapse" data-parent="#accordion">
                                        <p>dox2U’s Tally Plugin offers a range of features, such as attaching documents from vouchers & masters, one-click viewing of documents from Tally, sharing & collaborating on documents, etc.. It streamlines document processes, improves efficiency, and provides valuable insights for better decision-making. <br><br><a href="../contact-us">Contact Us</a>
                                        </p>
    
                                    </div>
                                </div>
    
                                <div class="acc-section">
                                    <div class="feature-comparision-Acordian-Item d-flex justify-content-between collapsed" data-toggle="collapse" href="#collapseFive">
                                        <h4 class="accordian-headings mb-0">Is dox2U Tally cloud DMS compatible with the latest version of Tally?
                                        </h4>
                                        <img src="../../../assets/Image/plus.svg" class="plus-img">
                                        <img src="../../../assets/Image/minus.svg" class="minus-img">
                                    </div>
                                    <div id="collapseFive" class="feature-comparision-Acordian-Body collapse" data-parent="#accordion">
                                        <p>Yes, dox2U’s Tally Plugin is regularly updated to ensure compatibility with the latest version of Tally accounting software and supports Tally ERP 9, Tally Prime (Release 3 as well) and also can be deployed with Tally on Cloud. It is designed to work seamlessly with Tally's most up-to-date features and functionalities.
    
                                        </p>
                                 
    
                              
            
                                    </div>
                                </div>
                                <div class="acc-section">
                                    <div class="feature-comparision-Acordian-Item d-flex justify-content-between collapse" data-toggle="collapse" href="#collapseFive">
                                        <h4 class="accordian-headings mb-0">Does dox2U’s Tally Plugin support both Tally's desktop and online versions?
                                        </h4>
                                        <img src="../../../assets/Image/plus.svg" class="plus-img">
                                        <img src="../../../assets/Image/minus.svg" class="minus-img">
                                    </div>
                                    <div id="collapseSix" class="feature-comparision-Acordian-Body collapse" data-parent="#accordion">
                                        <p>Yes, dox2U’s Tally Plugin supports both Tally's desktop (Silver & Gold) and online versions. It can be used with Tally installed on a local system or accessed through the cloud-based Tally.
    
                                        </p>
                                 
    
                              
            
                                    </div>
                                </div>
                                <div class="acc-section">
                                    <div class="feature-comparision-Acordian-Item d-flex justify-content-between collapsed" data-toggle="collapse" href="#collapseFive">
                                        <h4 class="accordian-headings mb-0">How easy is it to install and configure dox2U Tally DMS Software?
                                        </h4>
                                        <img src="../../../assets/Image/plus.svg" class="plus-img">
                                        <img src="../../../assets/Image/minus.svg" class="minus-img">
                                    </div>
                                    <div id="collapseSeven" class="feature-comparision-Acordian-Body collapse" data-parent="#accordion">
                                        <p>The installation and configuration process of dox2U’s Tally Plugin is user-friendly and straightforward. It typically involves downloading and installing the plugin, followed by a simple setup and activation process within Tally accounting software.
    
                                        </p>
                                 
    
                              
            
                                    </div>
                                </div>
                                <div class="acc-section">
                                    <div class="feature-comparision-Acordian-Item d-flex justify-content-between collapsed" data-toggle="collapse" href="#collapseFive">
                                        <h4 class="accordian-headings mb-0">Are there any additional costs associated with using dox2U Tally Plugin?
                                        </h4>
                                        <img src="../../../assets/Image/plus.svg" class="plus-img">
                                        <img src="../../../assets/Image/minus.svg" class="minus-img">
                                    </div>
                                    <div id="collapseEight" class="feature-comparision-Acordian-Body collapse" data-parent="#accordion">
                                        <p>No, all you need is to install the Plugin and subscribe to one of our subscription plans depending on your use case.
    
                                        </p>
                                 
    
                              
            
                                    </div>
                                </div>
                                <div class="acc-section">
                                    <div class="feature-comparision-Acordian-Item d-flex justify-content-between collapsed" data-toggle="collapse" href="#collapseFive">
                                        <h4 class="accordian-headings mb-0">What kind of support or documentation is available for dox2U’s Tally Plugin?
                                        </h4>
                                        <img src="../../../assets/Image/plus.svg" class="plus-img">
                                        <img src="../../../assets/Image/minus.svg" class="minus-img">
                                    </div>
                                    <div id="collapseNine" class="feature-comparision-Acordian-Body collapse" data-parent="#accordion">
                                        <p>dox2U typically provides comprehensive documentation, including installation guides, user manuals, FAQs, and troubleshooting resources. Additionally, support may be available through email, online chat, or a dedicated support portal for any queries or technical assistance needed.
    
                                        </p>
                                 
    
                              
            
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                   
                </div>
                <div class="row"style="margin:20px 6px;">
                    <p class="itelictext mb-0" id="check1"><i>Tally is a trademark of its respective owner and is not
                            affiliated, endorsed, connected or sponsored in any way to this website, mobile application or
                            any
                            of our affiliate sites. The same is used in accordance with honest practices and not used with
                            any
                            intention to misguide customers to take unfair advantage of the trademark's distinct character
                            or
                            harm the holder's reputation.</i>.</p>
                </div>
            </div>
        </div>
    </section> --> 
    
    

    
    