import { Component, ElementRef, HostListener, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { EmailService } from 'src/app/email.service';
import { connetedInformation, convinience, docketsHeroText, flexibility, tracability } from 'src/constants/product-content';
import { SEOService } from 'src/app/seo.service';

@Component({
  selector: 'app-dockets',
  templateUrl: './dockets.component.html',
  styleUrls: ['./dockets.component.scss']
})
export class DocketsComponent implements OnInit {
  @ViewChild('myVideo') videoElement!: ElementRef<HTMLVideoElement>;
  ngOnInit(): void {
    this.createLinkForCanonicalURL() /** to add canonical tags */
    //this.meta.addTag({rel: 'canonical', content: 'https://www.dox2u.com/dockets'});
    this.emailService.headerOff.next(true);
    this.meta.addTag({rel: 'canonical', content: 'https://www.dox2u.com/dockets'});
    let scriptBreadcrumbList1 = this.renderer2.createElement('script');
    scriptBreadcrumbList1.type = `application/ld+json`;
    scriptBreadcrumbList1.text = `{
      "@context": "https://schema.org/", 
    "@type": "BreadcrumbList", 
    "itemListElement": [{
      "@type": "ListItem", 
      "position": 1, 
      "name": "Home page",
      "item": "https://dox2u.com/"  
    },{
      "@type": "ListItem", 
      "position": 2, 
      "name": "Dockets page",
      "item": "https://www.dox2u.com/dockets"  
    }]
      }`
    
    this.renderer2.appendChild(this._document.body, scriptBreadcrumbList1);

    this.titleService.setTitle('Docket - File Cabinet - Document Management Software | dox2U","This Virtual File Cabinet Document Management Software System provided by dox2U, lets the users to search and retrieve documents conveniently.')/** For Page Title */
  }

  constructor(private meta: Meta, public emailService: EmailService, private seoService: SEOService, private titleService: Title, private renderer2: Renderer2, @Inject(DOCUMENT) private _document: Document){

         /*
     * Cannonical tag
     */
        
  }
  readonly docketsHeroText: typeof docketsHeroText = docketsHeroText;
  readonly convinience: typeof convinience = convinience;
  readonly flexibility: typeof flexibility = flexibility;
  readonly tracability: typeof tracability = tracability;
  readonly connetedInformation: typeof connetedInformation = connetedInformation;
  convenience_img:string="../../../../assets/Image/dockets/dox2u_dockets_convenience.svg"
  docket_organize_img:string="../../../../assets/Image/dockets/dox2u_dockets_organize.svg"
  docket_docuemt_img:string="../../../../assets/Image/dockets/dox2u_dockets_document.svg"
  docketInfo_img:string="../../../../assets/Image/dockets/dox2u_dockets_info.svg"
  docketsHero_img:string="../../../../assets/Image/dockets/dox2u_dockets_banner.svg"


   /**
   * Method to add cannomical tags dynamically
   */

   createLinkForCanonicalURL() {
    this.seoService.createLinkForCanonicalURL();
  }

  whatsappIntegrationImg: string=" ";
  videoURL: string = 'https://www.dox2u.com/e-docs/Dockets_video_v2.mp4'
  
  @HostListener('window:scroll', ['$event'])
  onScroll(event: Event): void {
    const isVideoInViewport = this.isElementInViewport(this.videoElement.nativeElement);
    if (!isVideoInViewport) {     
      this.videoElement.nativeElement.pause();  
    }
  }

  private isElementInViewport(element: HTMLElement): boolean {
    const rect = element.getBoundingClientRect();
    
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
    
  }


  scrollToGettingStarted() {
    
    const gettingStartedElement = document.getElementById('gettingStarted');
    if (gettingStartedElement) {
        const viewportHeight = window.innerHeight;
        const elementHeight = gettingStartedElement.clientHeight;
        const yOffset = (viewportHeight - elementHeight) / 2;
        const scrollOptions: ScrollToOptions = {
        top: gettingStartedElement.offsetTop - yOffset + 200,
        behavior: 'smooth',
      };
        window.scrollTo(scrollOptions);
      //gettingStartedElement.scrollIntoView({ behavior: 'smooth' });
    }
  }
}
