<section class="workspaces-banner document-handling-banner overflow-hidden">
  <app-product-left-text-with-button [imgSrc] = "docketsHero_img" [contentH]="docketsHeroText"></app-product-left-text-with-button >
</section>
<section class="docketVideo-container">
<div class="container productLeft overflow-hidden change-bg-whatsapp" id="whatsappIntegrationText">
  <div class="">
    <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto image-description">
      <div class="row d-flex align-items-center row-direction p-3">

        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 d-flex align-items-center flex-wrap content-feature">
          <div class="workspace-banner-content document-handling-banner-content image-content">
            <h6 class="heading-6 text-uppercase">What are Dockets?</h6>
            <h1 class="heading-1">Your information organized the way you prefer and aligned as per your business process, enhancing
            </h1>
            <!-- <p class="common-para">dox2U seamlessly integrates with WhatsApp for secure document sharing through secure links, controlled by OTPs and duration settings. No complex setup—just add <a class="dcoins-hover" title="WhatsApp being a paid service requires you to add dCoins to your dox2U wallet"> dCoins </a> to your wallet and start sharing instantly. Receive status updates on document delivery or reading.</p> -->
            <div class="dockets-feature"> <p class="common-para d-flex align-items-center"><span class="line-rect"></span>Accessibility</p>
              <p class="common-para d-flex align-items-center">     <span class="line-rect"></span>Traceability</p>
                <p class="common-para d-flex align-items-center">       <span class="line-rect"></span>Convenience
                </p>
          </div>
          </div>
        </div>

        <!-- <div class="col-xxl-1 d-xl-none d-xxl-block d-lg-none d-md-none d-sm-none"></div> -->

        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center">

          <img src={{whatsappIntegrationImg}} alt="document handling system" class="img-fluid img-size">

          <video #myVideo id="myVideo" width="803" height="452" controls>
            <source src={{videoURL}} type="video/mp4">
      Your browser does not support the video tag.
          </video>

        </div>
      </div>
    </div>
  </div>
</div></section>
<section class="img-section-container">
  <h1 class="heading-1 heading-align">Bring your scattered information together with Dockets</h1>
<app-right-image-left-text
  [imgUrl]="docketInfo_img"
  [content]="connetedInformation"
></app-right-image-left-text>
<app-left-image-right-text id="convenience"
  [imgUrl]="convenience_img"
  [content]="convinience"
></app-left-image-right-text>
<app-right-image-left-text
  [imgUrl]="docket_organize_img"
  [content]="flexibility"
></app-right-image-left-text>
<app-left-image-right-text id="tracability"
[imgUrl]="docket_docuemt_img"
[content]="tracability"
></app-left-image-right-text>


</section>
<section class="it-is-simple-sec">
  <div class="container">
    <div class="row">
      <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <div class="its-simple-top text-center">
          <h6 class="heading-6 yellow">HOW IT WORKS</h6>
          <h3 class="heading-3">It is simple!</h3>
        </div>
      </div>
    </div>
    <div class="row d-flex align-items-center">
      <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12">
        <div class="content-sec">
          <!--btn-blue-->
          <div class="btn-blue">
            <div>
              <span
                ><img
                  src="../../../../assets/Image/dockets/plus-icon.svg"
                  alt="icon"
                  class="img-fluid"
              /></span>
            </div>

            <p>Create templates</p>
          </div>
          <div class="border-left">
            <!-- <button class="btn-blue">Create templates</button> -->
            <p>
              Create multiple templates with the relevant types of documents you
              want in your Docket (Invoice, Shipping Bill, QC Records, etc)
            </p>
          </div>
          <!--btn-purple-->
          <div class="btn-blue btn-purple">
            <div>
              <span
                ><img
                  src="../../../../assets/Image/dockets/create-new-docket-icon.svg"
                  alt="icon"
                  class="img-fluid"
              /></span>
            </div>
            <p>Create new Docket</p>
          </div>
          <div class="border-left">
            <!-- <button class="btn-blue">Create templates</button> -->
            <p>
              Create a new Docket using any template with a Unique ID of your
              choice, which works as the common identifying parameter for
              grouping documents
            </p>
          </div>
          <!--btn-yellow-->
          <div class="btn-blue btn-yellow">
            <div>
              <span
                ><img
                  src="../../../../assets/Image/dockets/Upload-Documents-icon.svg"
                  alt="icon"
                  class="img-fluid"
              /></span>
            </div>

            <p>Upload Documents</p>
          </div>
          <div class="border-left">
            <!-- <button class="btn-blue">Create templates</button> -->
            <p>
              Upload documents directly to the Docket, link existing documents
              from your Workspace or send documents from your connected system
              using our Integrations
            </p>
          </div>
          <!--btn-green-->
          <div class="btn-blue btn-green">
            <div>
              <span
                ><img
                  src="../../../../assets/Image/dockets/Identify-the-missing-icon.svg"
                  alt="icon"
                  class="img-fluid"
              /></span>
            </div>

            <p>Identify the missing</p>
          </div>
          <div class="border-left border-last">
            <!-- <button class="btn-blue">Create templates</button> -->
            <p>
              Get a snapshot of documents already in your Docket and identify
              those missing from your defined collection
            </p>
          </div>
        </div>
      </div>
      <div
        class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex justify-content-end"
      >
        <div class="img-sec d-sm-none d-lg-block mobile-none">
          <img
            src="../../../../assets/Image/dockets/dox2u_dockets_how_it_works.svg"
            alt=""
            class="img-fluid"
          />
        </div>
      </div>
    </div>
  </div>
</section>
<!-- dockets slide -->
<section class="use-cases-dockets common-padd" >
  <div class="container">
    <div class="row">
      <div class="col-xxl-10 col-xl-10 col-lg-10 col-md-10 col-sm-12 mx-auto">
        <div class="slider-top text-center">
          <h6 class="heading-6">USE CASES</h6>
          <h2 class="heading-2">Get organized with Dockets!</h2>
        </div>
        <div class="dockets-slider">
          <div id="carouselExample" class="carousel slide" data-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item">
                <div class="carousel-item-top">
                  <img
                    src="../../../../assets/Image/dockets//logistics-icon.svg"
                    alt="Logistics-icon"
                  />
                  <h4 class="h4">Logistics</h4>
                </div>
                <div class="row">
                  <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                    <div class="slider-content-border">
                      <h4 class="h4">Problem:</h4>
                      <p class="common-para">
                        Typical logistics transaction involves multiple offices
                        and timezones across different geographies. Documents
                        often remain scattered at different locations and, at
                        times, are found missing when needed. It is critical
                        that all information remains organized and cataloged at
                        one place. A missing document could cost up to several
                        hundred thousand dollars in lawsuits.
                      </p>
                    </div>
                  </div>

                  <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                    <div class="slider-content-border">
                      <h4 class="h4">dox2U:</h4>
                      <p class="common-para">
                        Using dox2U’s Dockets, a leading logistics operator
                        managed to bring together all their documented
                        information into a single repository, accessible by
                        every user in every office. This convenience was
                        empowered by integration with their ERP, ensuring all
                        Jobs are efficiently docket-ized on dox2U and
                        information managed on their ERP is seamlessly pushed to
                        dox2U!
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="carousel-item">
                <div class="carousel-item-top">
                  <img
                    src="../../../../assets/Image/dockets/real-estate.svg"
                    alt="Logistics-icon"
                  />
                  <h4 class="h4">Real Estate</h4>
                </div>
                <div class="row">
                  <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                    <div class="slider-content-border">
                      <h4 class="h4">Problem:</h4>
                      <p class="common-para">
                        Compliance and the need to ensure that records are
                        always available dictates real estate companies to
                        manage massive amounts of documents. Challenges in going
                        digital with this archive was to ensure information
                        remains cohesively bound together. Using conventional
                        DMS solutions involved huge metadata inputs and tagging
                        to ensure each document is retrievable.
                      </p>
                    </div>
                  </div>

                  <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                    <div class="slider-content">
                      <h4 class="h4">dox2U:</h4>
                      <p class="common-para">
                        Using dox2U’s Dockets, a prominent real estate company
                        with thousands of customers brought together all
                        document trails using a single Customer ID. Convenience
                        of finding every document exchanged with a customer at a
                        single place and on a click not only helped improve
                        efficiencies but also improved customer experience which
                        added to their topline &amp; bottomlines.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item active">
                <div class="carousel-item-top">
                  <img
                    src="../../../../assets/Image/dockets/pharma.svg"
                    alt="Logistics-icon"
                  />
                  <h4 class="h4">Pharma</h4>
                </div>
                <div class="row">
                  <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                    <div class="slider-content-border">
                      <h4 class="h4">Problem:</h4>
                      <p class="common-para">
                        Batch Manufacturing Records have always been a
                        pain-point with pharma manufacturers. Stringent
                        compliances require them to maintain records for several
                        years against every batch of drug manufactured.
                        Overflowing doc warehouses make it difficult to retrieve
                        information and lost or misplaced docs could have
                        catastrophic results to their continuing operations.
                      </p>
                    </div>
                  </div>

                  <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                    <div class="slider-content">
                      <h4 class="h4">dox2U:</h4>
                      <p class="common-para">
                        Using dox2U’s Dockets, a leading listed major
                        pharmaceutical manufacturer with several facilities
                        across the country brought all their BMRs onto a digital
                        platform conveniently organized against unique Batch
                        Numbers. No longer are compliance audits a pain with all
                        documents against any batch simply docket-ized within
                        dox2U and retrievable with a single click!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          
          <!-- <button
            class="carousel-control-prev docket-prev"
            type="button"
            data-bs-target="#carouselExample"
            data-bs-slide="prev"
          >
            <span class="" aria-hidden="true"
              ><div class="long-arrow-left"></div
            ></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button
            class="carousel-control-next docket-next"
            type="button"
            data-bs-target="#carouselExample"
            data-bs-slide="next"
          >
            <span class="" aria-hidden="true"
              ><div class="long-arrow-right"></div
            ></span>
            <span class="visually-hidden">Next</span>
          </button> -->

          <a class="carousel-control-prev docket-prev" href="#carouselExample" data-slide="prev">
            <span class="long-arrow-left"></span>
          </a>
          <a class="carousel-control-next docket-next" href="#carouselExample" data-slide="next">
            <span class="long-arrow-right"></span>
          </a>
        </div>
      </div>
      </div>
    </div>
  </div>
</section>

<section class="footer-bg">
  <app-getting-started></app-getting-started>
</section>
<app-gradient-divider></app-gradient-divider>

