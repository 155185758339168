import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-why-choose-dox2-u',
  templateUrl: './why-choose-dox2-u.component.html',
  styleUrls: ['./why-choose-dox2-u.component.scss']
})
export class WhyChooseDox2UComponent implements OnInit {

  scrollToGettingStarted() {
    
    const gettingStartedElement = document.getElementById('gettingStarted');
    if (gettingStartedElement) {
        const viewportHeight = window.innerHeight;
        const elementHeight = gettingStartedElement.clientHeight;
        const yOffset = (viewportHeight - elementHeight) / 2;
        const scrollOptions: ScrollToOptions = {
        top: gettingStartedElement.offsetTop - yOffset + 200,
        behavior: 'smooth',
      };
        window.scrollTo(scrollOptions);
      //gettingStartedElement.scrollIntoView({ behavior: 'smooth' });
    }
  }

  constructor(private titleService: Title, private meta: Meta, private renderer2: Renderer2, @Inject(DOCUMENT) private _document: Document) {
    /*
      * Cannonical tag
     */
   
  }
  ngOnInit(): void {
    this.meta.addTag({rel: 'canonical', content: 'https://www.dox2u.com/why-choose-dox2u'});
    let scriptBreadcrumbListChoose = this.renderer2.createElement('script');
    scriptBreadcrumbListChoose.type = `application/ld+json`;
    scriptBreadcrumbListChoose.text = `{
      "@context": "https://schema.org/", 
    "@type": "BreadcrumbList", 
    "itemListElement": [{
      "@type": "ListItem", 
      "position": 1, 
      "name": "Home page",
      "item": "https://dox2u.com/"  
    },{
      "@type": "ListItem", 
      "position": 2, 
      "name": "Why choose dox2u page",
      "item": "https://www.dox2u.com/why-choose-dox2u"  
    }]
      }`
    
    this.renderer2.appendChild(this._document.body, scriptBreadcrumbListChoose);
    this.titleService.setTitle('Choose dox2U - Benefits of Having a Document Management System","Get the most out of dox2U: a new-age Document Management System (DMS) with benefits & advantages that is a one-fit model for all.')/** For Page Title */
  }
}
